
import { ElMessageBox } from "element-plus";
import { defineComponent } from "vue";
import _ from "lodash";
import helpers from "@/helpers/global";
import settings from "@/helpers/settings";
const noop = () => {
  // no empty function
};
let intervalSession = setTimeout(noop, 10);
let intervalCountdown = setTimeout(noop, 10);

export default defineComponent({
  name: "LogicTest",
  inheritAttrs: false,
  watch: {
    step(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    // workStyleScenarios: {
    //   handler(newValue) {
    //     if (newValue) {
    //       this.checkingNextStep();
    //     }
    //   },
    //   deep: true,
    // },
    doPreviousStep(value) {
      // console.log(`doPreviousStep(${value ? 'true' : 'false'})`);
      value && this.gotoPreviousStep();
    },
    doNextStep(value) {
      // console.log(`doNextStep(${value ? 'true' : 'false'})`);
      value && this.gotoNextStep();
    },
    timerLabel(value) {
      this.$emit("setTimerLabel", value);
    },
    timer(value) {
      const percentage =
        ((this.percentageLabel - value) / this.percentageLabel) * 100;
      this.$emit("setTimerPercentage", percentage);
    },
  },
  computed: {
    companyLogoUrl(): string {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl || defaultLogoUrl;
    },
    extratime(): any {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const extratime = Number(formTemplate.extra_time) || 0;
      return extratime;
    },
    companyColor(): string {
      const defaultCompanyColor = "#1B1C1E";
      const user = this.$store.getters["user/user"];
      const userRoute = this.$store.getters["routes/user"];
      if (user && user.color) {
        return user.color;
      } else if (userRoute && userRoute.color) {
        return userRoute.color;
      }
      return defaultCompanyColor;
    },
    workStyleScenarios(): any {
      const workStyleScenarios =
        this.$store.getters["assessmentSection/logicTest"] || {};
      if (!workStyleScenarios.parsed) {
        let scenarios = [];
        for (const i in workStyleScenarios.scenarios) {
          scenarios.push(workStyleScenarios.scenarios[i]);
        }
        scenarios = _.sortBy(scenarios, ["order"]);
        const $scenarios: any = {};
        for (const i in scenarios) {
          const key = "scenario" + scenarios[i].order;
          $scenarios[key] = scenarios[i];
        }
        workStyleScenarios.scenarios = $scenarios;
        workStyleScenarios.parsed = true;
      }
      return workStyleScenarios;
    },
    progressShowing() {
      const step = this.step;
      const array1 = ["Work_Style_Identifier"];

      const found = array1.find((element) => element == step);
      if (found) {
        return true;
      }
      return false;
    },
    timeSection(): string {
      return this.$store.getters["routes/timeSection"];
    },
    isLightColor(): boolean {
      const user = this.$store.getters["routes/user"] || {};
      const color = user.color || "#1B1C1E";
      return helpers.isLightColor(color);
    },
  },
  props: [
    "lastSection",
    "currentStep",
    "doPreviousStep",
    "doNextStep",
    "setTimerLabel",
    "setTimerPercentage",
  ],
  data() {
    return {
      step: "introduce_Work_Style", // introduce_Work_Style
      hasOpenEndedQuestion: false,
      skipOpenEndedQuestion: false,
      openedQuestion: "",
      saved: false,
      canNextStep: false,
      percentageLabel: 0,
      curScenarioOrder: 1,
      curQuestionOrder: 1,
      timer: 0,
      timerLabel: "0:00",
      timeout: 300,
      isStarted: false,
      canNextStepAll: false,
    };
  },
  mounted() {
    // const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
    // this.hasOpenEndedQuestion = formTemplate.open_ended_question || false;
    // console.log(settings, "settings");
    this.timeout = settings.LOGIC_TEST_TIME * 60 + this.extratime * 60;
    // this.timeout = 3600;
    const minute = Math.floor(this.timeout / 60);

    // @deprecated
    // this.countDownTimeSession();

    this.$emit("setHasPreviousStep", true);
    this.$emit("setCanPreviousStep", false);

    this.$emit("setHasNextStep", true);
    this.$emit("setCanNextStep", false);

    // Get started with popup
    if (!this.isStarted) {
      const order = this.currentStep.order - 1;
      ElMessageBox.alert(
        (this as any).$t("question.workStyleIdentifier.get_started_message", {
          step: order,
          minute: minute,
        }),
        (this as any).$t("question.common.move_on_step_n", {
          step: order,
        }),
        {
          dangerouslyUseHTMLString: true,
          showClose: true,
          confirmButtonText: (this as any).$t("question.common.Start"),
          callback: () => {
            this.getStarted();
          },
        }
      );
    }

    this.applyStepperButtons();
  },
  methods: {
    getStarted() {
      this.countDownTime();
      // @deprecated
      // this.clearIntervalSession();
      this.step = "Work_Style_Identifier";

      // @fixed test
      // const scenarios = this.workStyleScenarios.scenarios;
      // for (const i in scenarios) {
      //   const questions = scenarios[i].questions;
      //   for (const j in questions) {
      //     questions[j].options[0].checked = true;
      //   }
      // }
    },
    gotoPreviousStep() {
      console.log("gotoPreviousStep()");

      if (this.curQuestionOrder > 1) {
        this.curQuestionOrder--;
      } else {
        if (this.curScenarioOrder > 1) {
          this.curScenarioOrder--;

          // last order of first scenarios
          const scenarios = this.workStyleScenarios.scenarios;
          for (const i in scenarios) {
            const questions = scenarios[i].questions;
            this.curQuestionOrder = questions[questions.length - 1].order;
            break;
          }
        }
      }
      this.applyStepperButtons();
    },
    gotoNextStep() {
      // console.log("gotoNextStep()");
      // console.log(this.step, "this.step");

      if (this.step == "Work_Style_Identifier") {
        const scenarios = this.workStyleScenarios.scenarios;
        let lastSectionOrder = 1;
        let lastSectionKey: any;
        for (const i in scenarios) {
          lastSectionOrder = scenarios[i].order;
          lastSectionKey = i;
        }
        let lastQuetionOrder = 1;
        for (const i in scenarios[lastSectionKey].questions) {
          const question = scenarios[lastSectionKey].questions[i];
          lastQuetionOrder = question.order;
        }

        const isLastQuestion =
          this.curScenarioOrder == lastSectionOrder &&
          this.curQuestionOrder == lastQuetionOrder;
        if (isLastQuestion) {
          this.saveTimeSection();
          this.lastSection ? this.finishAssessment() : this.nextSectionBTN();
        } else {
          let nextQuestionOrder = this.curQuestionOrder + 1;
          // console.log(nextQuestionOrder, "nextQuestionOrder");
          let found = false;
          for (const i in scenarios) {
            if (this.curScenarioOrder != scenarios[i].order) continue;
            for (const j in scenarios[i].questions) {
              // console.log(
              //   scenarios[i].questions[j].order,
              //   "scenarios[i].questions[j].order"
              // );
              if (scenarios[i].questions[j].order == nextQuestionOrder) {
                found = true;
                this.curQuestionOrder = nextQuestionOrder;
                break;
              }
            }
          }
          if (!found) {
            this.curScenarioOrder++;
            this.curQuestionOrder = 1;
          }
        }

        // console.log(this.curScenarioOrder, "this.curScenarioOrder");
        // console.log(this.curQuestionOrder, "this.curQuestionOrder");
        this.applyStepperButtons();
      } else if (this.step == "opened_question") {
        this.finishOpenedQuestion();
        this.$emit("setCanNextStep", true);
      } else if (this.step == "complete") {
        this.lastSection ? this.finishAssessment() : this.nextSectionBTN();
      }
    },
    optionCls(option: { value: any }) {
      // const colors = [
      //   "color-15CE95",
      //   "color-FAA13A",
      //   "color-FA8B3A",
      //   "color-FA5F3A",
      //   "color-FA3A3A",
      // ];
      let cls = "";
      const index = parseInt(option.value);
      // return colors[index] ? colors[index] : "";
      if (index) {
        cls = "";
      }

      return cls;
    },
    applyStepperButtons() {
      let count = 0;
      const scenarios = this.workStyleScenarios.scenarios;

      for (const i in scenarios) {
        if (this.curScenarioOrder != scenarios[i].order) continue;
        for (const j in scenarios[i].questions) {
          if (this.curQuestionOrder != scenarios[i].questions[j].order)
            continue;
          for (const k in scenarios[i].questions[j].options) {
            const o = scenarios[i].questions[j].options[k];
            if (o.checked) {
              count++;
              break;
            }
          }
        }
      }

      this.canNextStepAll = count == 1;
      this.$emit("setCanNextStep", this.canNextStepAll);
      this.$emit(
        "setCanPreviousStep",
        !(this.curScenarioOrder == 1 && this.curQuestionOrder == 1)
      );

      const lastKey: any = Object.keys(scenarios).pop();
      const lastScenarioOrder = _.get(scenarios, `${lastKey}.order`); //scenarios[lastKey].order;
      const lastQuetionOrder = _.get(
        scenarios,
        `${lastKey}.questions[${scenarios[lastKey].questions.length - 1}].order`
      ); //scenarios[lastKey].questions[scenarios[lastKey].questions.length - 1].order;

      // console.log(lastScenarioOrder, "lastScenarioOrder");
      // console.log(lastQuetionOrder, "lastQuetionOrder");
      const isLastQuestion =
        this.curScenarioOrder == lastScenarioOrder &&
        this.curQuestionOrder == lastQuetionOrder;
      this.$emit("setHasNextStep", !isLastQuestion);
    },
    onClickOption(scenario: any, question: any, option: any) {
      const scenarios = this.workStyleScenarios.scenarios;
      for (const i in scenarios) {
        if (scenarios[i].id == scenario.id) {
          for (const j in scenarios[i].questions) {
            const _question = scenarios[i].questions[j];
            if (_question.id != question.id) continue;
            for (const k in scenarios[i].questions[j].options) {
              const o = scenarios[i].questions[j].options[k];
              o.checked = o.code == option.code;
              if (o.checked) {
                this.canNextStep = true;
              }
            }
          }
        }
      }
      this.applyStepperButtons();
    },
    checkingNextStep() {
      const order = this.curScenarioOrder;
      const nextOrder = order + 1;
      let found = false;
      for (const i in this.workStyleScenarios.scenarios) {
        if (found) break;
        const scenario = this.workStyleScenarios.scenarios[i];
        found = scenario.order == nextOrder;
      }
      this.canNextStep = found;
    },
    completeAssessment() {
      // this.step = "opened_question";
      this.step = this.hasOpenEndedQuestion ? "opened_question" : "complete";
    },
    makeFormValues() {
      let values: any = {};
      const name = this.workStyleScenarios.name;
      values[name] = {};
      const scenarios = this.workStyleScenarios.scenarios;
      for (const i in scenarios) {
        const group = _.snakeCase(this.workStyleScenarios.scenarios[i].name);
        values[name][group] = {};
        for (const j in scenarios[i].questions) {
          const question = scenarios[i].questions[j];
          for (const k in question.options) {
            if (question.options[k].checked) {
              const answer = {
                id: question.id,
                // value: parseInt(question.options[k].value),
                code: question.options[k].code,
              };
              values[name][group][answer.id] = answer.code;
            }
          }
        }
      }
      if (this.hasOpenEndedQuestion && !this.skipOpenEndedQuestion) {
        const feedback = this.openedQuestion || "";
        values.work_style_identifier_feedback = feedback.trim();
      }
      return values;
    },
    async finishAssessment() {
      // console.log("finishAssessment()");

      // @fixed test
      // if (this.$store) return;

      clearInterval(intervalCountdown);
      const values = this.saved ? null : this.makeFormValues();

      // @fixed test
      // console.log(values, "values");
      // if (this.$store) return;

      await this.$store.dispatch("assessment/finish", values, { root: true });
      this.saved = true;
      this.$emit("finishAssessment");
      /*
      if (window.closed) {
        window.close();
      } else {
        // window.open("https://myculture.happily.ai/", "_self");
        const formTemplate =
          this.$store.getters["assessment/formTemplate"] || {};
        const redirectUrl =
          formTemplate.redirect_url ||
          "https://myculture.ai/thank-you-for-submitting";
        window.open(redirectUrl, "_self");
      }
      //*/
    },
    countDownTime() {
      this.percentageLabel = this.timeout;
      this.timer = this.timeout;
      intervalCountdown = setInterval(() => {
        if (this.timer === 0) {
          ElMessageBox.alert(
            (this as any).$t("popup.alert.alert_box_1.title"),
            (this as any).$t("popup.alert.alert_box_1.description"),
            {
              showClose: false,
              confirmButtonText: (this as any).$t("popup.optional.Continue"),
              callback: () => {
                this.saveTimeSection();
                if (this.hasOpenEndedQuestion) {
                  this.step = "opened_question";
                  this.nextSectionTimeOut();
                } else {
                  this.nextSectionTimeOut();
                  if (this.lastSection) {
                    this.finishAssessment();
                  }
                  this.nextSectionBTN();
                }
              },
            }
          );
          clearInterval(intervalCountdown);
        } else if (this.step == "complete" || this.step == "opened_question") {
          clearInterval(intervalCountdown);
        } else {
          this.timer--;
          let minutes = this.timer % 60;
          this.timerLabel = `${Math.floor(this.timer / 60)}:${
            minutes < 10 ? "0" + minutes : minutes
          }`;
        }
      }, 1000);
    },
    clearIntervalSession() {
      clearInterval(intervalSession);
    },
    countDownTimeSession() {
      // this.timer = 5 * 60;
      this.timer = this.timeout;
      intervalSession = setInterval(() => {
        if (this.timer === 0) {
          ElMessageBox.alert(
            (this as any).$t("popup.alert.alert_box_2.title"),
            (this as any).$t("popup.alert.alert_box_2.description"),
            {
              showClose: false,
              confirmButtonText: (this as any).$t(
                "popup.optional.Go_to_Next_Section"
              ),
              callback: () => {
                this.nextSectionBTN();
              },
            }
          );
          clearInterval(intervalSession);
        } else {
          this.timer--;
        }
      }, 1000);
    },
    setupPreviousStep() {
      const index = this.curScenarioOrder;
      this.curScenarioOrder = index - 1;
    },
    setupNexStep() {
      if (
        this.curScenarioOrder ==
        Object.keys(this.workStyleScenarios.scenarios).length
      ) {
        this.completeAssessment();
        this.saveTimeSection();
      } else if (this.canNextStep) {
        this.checkingNextStep();
        this.curScenarioOrder++;
      }
    },
    finishOpenedQuestion() {
      this.step = "complete";
    },
    nextSectionBTN() {
      // console.log("nextSectionBTN()");
      clearInterval(intervalCountdown);
      const values = this.makeFormValues();
      this.$emit("finishValueAndTraits", values);
    },
    // @todo I'll refactor this after launch beta version (common function)
    lang(obj: any, attr: string) {
      const lang = (this as any).$i18n.getLocale(0) || "en";
      let label = "";
      if (_.isObject(obj)) {
        if (lang != "en") {
          attr = attr + "_" + lang;
        }
        if (Object.prototype.hasOwnProperty.call(obj, attr)) {
          label = (obj as any)[attr];
        }
      }
      return label;
    },
    optionPrefix(obj: any) {
      const lang = (this as any).$i18n.getLocale(0) || "en";
      const code = _.get(obj, "code") || "";
      let prefix = "";
      if (code) {
        const prefixMap = {
          en: {
            "1": "A) ",
            "2": "B) ",
            "3": "C) ",
            "4": "D) ",
          },
          th: {
            "1": "ก) ",
            "2": "ข) ",
            "3": "ค) ",
            "4": "ง) ",
          },
        };
        prefix = _.get(prefixMap, `${lang}.${code}`);
      }
      return prefix;
    },
    async nextSectionTimeOut() {
      const code = this.$router.currentRoute.value.params.code || "";
      const statusSectionWork = true;
      if (!code) {
        this.$router.replace(`/404`);
        return;
      }
      await this.$store.dispatch(
        "assessment/sectionStates",
        { code, statusSectionWork },
        {
          root: true,
        }
      );
    },
    async saveTimeSection() {
      const code = this.$router.currentRoute.value.params.code || "";
      const timeSectionLogicTest = this.timer ? this.timer : 0;
      if (!code) {
        this.$router.replace(`/404`);
        return;
      }
      await this.$store.dispatch(
        "assessment/saveSectionTime",
        { code, timeSectionLogicTest },
        { root: true }
      );
    },
    nl2br(value: string) {
      return value.replace(/([^>])\n/g, "$1<br />\n");
    },
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, normalizeStyle as _normalizeStyle, renderList as _renderList } from "vue"
import _imports_0 from '@/assets/image/logo_myculture.svg'
import _imports_1 from '@/assets/icons/white_arrow_right_24px.png'
import _imports_2 from '@/assets/icons/navigate_right_black_24px.png'
import _imports_3 from '@/assets/icons/white_check_24px.png'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "menu-container flex-center" }
const _hoisted_4 = { class: "menu-navbar-for-test" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  src: _imports_0,
  alt: "MyCulture logo",
  class: "company-logo-image",
  style: {"width":"18rem"}
}
const _hoisted_7 = { class: "assessment-card-container on-top-mobile-screen" }
const _hoisted_8 = {
  key: 0,
  class: "content-46w"
}
const _hoisted_9 = { class: "mx-auto" }
const _hoisted_10 = { class: "card-title" }
const _hoisted_11 = { class: "min-m-mt-24" }
const _hoisted_12 = {
  class: "fs-14 fc-696C80",
  style: {"line-height":"160%"}
}
const _hoisted_13 = { class: "fw-700" }
const _hoisted_14 = { class: "fs-16 mt-24" }
const _hoisted_15 = { class: "ul-style" }
const _hoisted_16 = {
  key: 1,
  class: "card-question-padding box-card mb-32 bg-FFFFFF"
}
const _hoisted_17 = { class: "width-max-520" }
const _hoisted_18 = { class: "card-title" }
const _hoisted_19 = { class: "fs-16 mt-12" }
const _hoisted_20 = { class: "flex-mid" }
const _hoisted_21 = {
  key: 1,
  class: "menu-container flex-center v2 mobile"
}
const _hoisted_22 = { class: "body-width-reponsive-max" }
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  key: 1,
  src: _imports_0,
  alt: "MyCulture logo",
  class: "company-logo-image",
  style: {"width":"18rem"}
}
const _hoisted_25 = {
  key: 2,
  class: "menu-container flex-center v2"
}
const _hoisted_26 = { class: "body-width-reponsive-max" }
const _hoisted_27 = { class: "el-progres-wrapper" }
const _hoisted_28 = { class: "time-label-wrapper" }
const _hoisted_29 = { class: "time" }
const _hoisted_30 = {
  class: "nav-bar-container flex-center",
  style: {"flex-direction":"row"}
}
const _hoisted_31 = {
  class: "flex-center",
  style: {"width":"30%"}
}
const _hoisted_32 = { class: "label-container" }
const _hoisted_33 = { class: "nav-text-header" }
const _hoisted_34 = { style: {"width":"70%","height":"50px","flex-basis":"100%","display":"flex","align-items":"center","place-content":"flex-end"} }
const _hoisted_35 = {
  key: 0,
  src: _imports_2,
  class: "icon-20 mr-12",
  alt: "navigate_right_black_24px",
  style: {"transform":"rotate(180deg)"}
}
const _hoisted_36 = {
  key: 1,
  src: _imports_1,
  class: "icon-20 mr-12",
  alt: "white_arrow_right_24px",
  style: {"transform":"rotate(180deg)"}
}
const _hoisted_37 = { class: "fs-12 fw-700" }
const _hoisted_38 = { class: "fs-12 fw-700" }
const _hoisted_39 = {
  key: 0,
  src: _imports_2,
  class: "icon-20 ml-12",
  alt: "navigate_right_black_24px"
}
const _hoisted_40 = {
  key: 1,
  src: _imports_1,
  class: "icon-20 ml-12",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_41 = {
  key: 1,
  width: "3rem",
  height: "3rem",
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve"
}
const _hoisted_42 = { class: "fs-12 fw-700" }
const _hoisted_43 = { class: "ml-24 flex-mid" }
const _hoisted_44 = {
  key: 3,
  class: "ml-16 btn02 btn-40 disable-true"
}
const _hoisted_45 = { class: "fs-12 fw-700" }
const _hoisted_46 = { class: "ml-24 flex-mid" }
const _hoisted_47 = {
  key: 4,
  class: "ml-16 btn02 btn-40 disable-true"
}
const _hoisted_48 = { class: "fs-12 fw-700 mr-16" }
const _hoisted_49 = {
  key: 3,
  class: "body-width v2",
  id: "assessment_test"
}
const _hoisted_50 = {
  class: "body-container-with-step",
  style: {"margin-top":"6.4rem"}
}
const _hoisted_51 = { class: "step-menu-container vertical" }
const _hoisted_52 = ["onClick"]
const _hoisted_53 = { class: "menu-inner" }
const _hoisted_54 = { class: "step-num" }
const _hoisted_55 = { class: "step-name" }
const _hoisted_56 = {
  key: 0,
  class: "step-desc"
}
const _hoisted_57 = { class: "assessment-test-wrapper v2" }
const _hoisted_58 = { key: 0 }
const _hoisted_59 = { key: 1 }
const _hoisted_60 = { key: 2 }
const _hoisted_61 = { key: 3 }
const _hoisted_62 = { key: 4 }
const _hoisted_63 = { key: 5 }
const _hoisted_64 = { key: 6 }
const _hoisted_65 = {
  key: 4,
  class: "thank-you-page"
}
const _hoisted_66 = { class: "inner" }
const _hoisted_67 = { class: "logo-wrapper flex-mid" }
const _hoisted_68 = ["src"]
const _hoisted_69 = {
  key: 1,
  src: _imports_0,
  alt: "MyCulture logo",
  class: "company-logo-image",
  style: {"width":"18rem"}
}
const _hoisted_70 = { class: "content-wrapper" }
const _hoisted_71 = { class: "title" }
const _hoisted_72 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirm_candidate = _resolveComponent("confirm-candidate")!
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_values_and_traits = _resolveComponent("values-and-traits")!
  const _component_oaic_archetypes = _resolveComponent("oaic-archetypes")!
  const _component_acceptable_behaviors = _resolveComponent("acceptable-behaviors")!
  const _component_work_style_identifier = _resolveComponent("work-style-identifier")!
  const _component_logic_test = _resolveComponent("logic-test")!
  const _component_human_skills = _resolveComponent("human-skills")!
  const _component_custom_surveys = _resolveComponent("custom-surveys")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.step == 'confirm_candidate' || _ctx.step == 'get_start')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`${_ctx.lang == 'th' ? 'font-Kanit' : 'Urbanist'} assessment-test`),
          style: {"line-height":"160%"}
        }, [
          (_ctx.step == 'confirm_candidate')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_confirm_candidate, { onFinishConfirmCandidate: _ctx.finishConfirmCandidate }, null, 8, ["onFinishConfirmCandidate"])
              ]))
            : (_ctx.step == 'get_start')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      (_ctx.companyLogoUrl)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: _ctx.companyLogoUrl,
                            alt: "Happily logo",
                            class: "company-logo-image",
                            style: {"width":"18rem"}
                          }, null, 8, _hoisted_5))
                        : (_openBlock(), _createElementBlock("img", _hoisted_6))
                    ])
                  ]),
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "mock-menu-container" }, null, -1)),
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.step == 'get_start')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.greeting), 1),
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, [
                                _createTextVNode(_toDisplayString(_ctx.$t("assessmentTest.Thank_you_for_taking")) + " ", 1),
                                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.creator.companyName), 1),
                                _createTextVNode(_toDisplayString(_ctx.$t("main.Thank_you_for_taking_end")) + ",  " + _toDisplayString(_ctx.$t("assessmentTest.A_few_things_you_should")), 1)
                              ]),
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("ul", _hoisted_15, [
                                  _createElementVNode("li", null, _toDisplayString(_ctx.$t("assessmentTest.Plan_to_take")) + " " + _toDisplayString(_ctx.timeSection + _ctx.extratime) + " " + _toDisplayString(_ctx.$t("assessmentTest.minutes_to_complete_this")), 1),
                                  _createElementVNode("li", null, _toDisplayString(_ctx.$t("assessmentTest.It_is_in_your_best_interest")), 1),
                                  _createElementVNode("li", null, _toDisplayString(_ctx.$t("assessmentTest.There_is_no_right")), 1),
                                  _createElementVNode("li", null, _toDisplayString(_ctx.$t("assessmentTest.If_you_re_unsure")), 1)
                                ])
                              ])
                            ])
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(["btn02 btn-primary mt-24", `${_ctx.data ? '' : 'x-btn-state-loading'}`]),
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.nextStepBTN && _ctx.nextStepBTN(...args)))
                          }, [
                            _createTextVNode(_toDisplayString(_ctx.$t("btn.Get_Started")) + " ", 1),
                            _cache[7] || (_cache[7] = _createElementVNode("img", {
                              class: "arrow-right icon-18",
                              src: _imports_1,
                              alt: "icons_white_arrow_right_24px"
                            }, null, -1))
                          ], 2)
                        ]))
                      : (_ctx.step == 'complete')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t("assessmentTest.You_ve_completed_all_section")), 1),
                              _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t(
                  "assessmentTest.Thank_you_for_taking_to_complete_the_assessment"
                )), 1),
                              _createElementVNode("div", _hoisted_20, [
                                _createElementVNode("div", {
                                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.finishAssessment && _ctx.finishAssessment(...args))),
                                  class: "mx-auto btn02 btn-40 btn-primary",
                                  style: {"margin-top":"8rem"}
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close")), 1)
                                ])
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (
      _ctx.step != 'confirm_candidate' && _ctx.step != 'get_start' && _ctx.step != 'complete'
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            (_ctx.companyLogoUrl)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.companyLogoUrl,
                  alt: "Happily logo",
                  class: "company-logo-image",
                  style: {"width":"18rem"}
                }, null, 8, _hoisted_23))
              : (_openBlock(), _createElementBlock("img", _hoisted_24))
          ])
        ]))
      : _createCommentVNode("", true),
    (
      _ctx.step != 'confirm_candidate' && _ctx.step != 'get_start' && _ctx.step != 'complete'
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.timerLabel), 1),
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "time-label" }, "Min Remaining", -1))
              ]),
              _createVNode(_component_el_progress, {
                percentage: _ctx.timerPercentage,
                "show-text": false,
                color: _ctx.companyColor
              }, null, 8, ["percentage", "color"])
            ]),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.backToLastPage && _ctx.backToLastPage(...args))),
                  class: "btn-left"
                }, _cache[10] || (_cache[10] = [
                  _createElementVNode("img", {
                    src: _imports_2,
                    alt: "icons_navigate_right_black_24px",
                    class: "icon-16 animetion",
                    style: {"transform":"rotate(180deg)"}
                  }, null, -1)
                ])),
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t("Assessment")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_34, [
                (!_ctx.isFirstStep && _ctx.hasPreviousStep)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.gotoPreviousStep && _ctx.gotoPreviousStep(...args))),
                      class: _normalizeClass(["ml-16 btn02 btn-40 btn-previous", `${_ctx.canPreviousStep ? '' : 'disable-true'}`])
                    }, [
                      (_ctx.isLightColor || _ctx.canPreviousStep)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_35))
                        : (_openBlock(), _createElementBlock("img", _hoisted_36)),
                      _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.$t("create.Previous")), 1)
                    ], 2))
                  : _createCommentVNode("", true),
                (!_ctx.isLastStep || _ctx.hasNextStep)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.gotoNextStep && _ctx.gotoNextStep(...args))),
                      class: _normalizeClass(["ml-16 btn02 btn-40 btn-next", `${
              _ctx.canNextStep && _ctx.publishSubmitStatus
                ? 'btn-primary'
                : 'disable-true'
            }`])
                    }, [
                      _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.$t("create.Next")), 1),
                      (_ctx.publishSubmitStatus)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (_ctx.isLightColor)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_39))
                              : (_openBlock(), _createElementBlock("img", _hoisted_40))
                          ], 64))
                        : (_openBlock(), _createElementBlock("svg", _hoisted_41, _cache[11] || (_cache[11] = [
                            _createElementVNode("path", {
                              fill: "#aaa",
                              d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                            }, [
                              _createElementVNode("animateTransform", {
                                attributeName: "transform",
                                attributeType: "XML",
                                type: "rotate",
                                dur: "1s",
                                from: "0 50 50",
                                to: "360 50 50",
                                repeatCount: "indefinite"
                              })
                            ], -1)
                          ])))
                    ], 2))
                  : _createCommentVNode("", true),
                (
              _ctx.isLastStep && !_ctx.hasNextStep && _ctx.publishSubmitStatus && _ctx.canNextStep
            )
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.publishSubmit && _ctx.publishSubmit(...args))),
                      class: "ml-16 btn02 btn-40 btn-primary"
                    }, [
                      _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.$t("btn.Submit")), 1),
                      _createElementVNode("div", _hoisted_43, [
                        _createElementVNode("img", {
                          src: _imports_3,
                          class: "icon-20",
                          style: _normalizeStyle(`${_ctx.isLightColor ? 'filter: invert(1)' : ''}`),
                          alt: "Submit"
                        }, null, 4)
                      ])
                    ]))
                  : (
              _ctx.isLastStep && !_ctx.hasNextStep && _ctx.publishSubmitStatus && !_ctx.canNextStep
            )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                        _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t("btn.Submit")), 1),
                        _createElementVNode("div", _hoisted_46, [
                          _createElementVNode("img", {
                            src: _imports_3,
                            class: "icon-20",
                            style: _normalizeStyle(`${_ctx.isLightColor ? 'filter: invert(1)' : ''}`),
                            alt: "Submit"
                          }, null, 4)
                        ])
                      ]))
                    : (_ctx.isLastStep && !_ctx.hasNextStep)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                          _createElementVNode("span", _hoisted_48, _toDisplayString(_ctx.$t("btn.Submit")), 1),
                          _cache[12] || (_cache[12] = _createElementVNode("svg", {
                            width: "3rem",
                            height: "3rem",
                            version: "1.1",
                            id: "L9",
                            xmlns: "http://www.w3.org/2000/svg",
                            "xmlns:xlink": "http://www.w3.org/1999/xlink",
                            x: "0px",
                            y: "0px",
                            viewBox: "0 0 100 100",
                            "enable-background": "new 0 0 0 0",
                            "xml:space": "preserve"
                          }, [
                            _createElementVNode("path", {
                              fill: "#aaa",
                              d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                            }, [
                              _createElementVNode("animateTransform", {
                                attributeName: "transform",
                                attributeType: "XML",
                                type: "rotate",
                                dur: "1s",
                                from: "0 50 50",
                                to: "360 50 50",
                                repeatCount: "indefinite"
                              })
                            ])
                          ], -1))
                        ]))
                      : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (
      _ctx.step != 'confirm_candidate' && _ctx.step != 'get_start' && _ctx.step != 'complete'
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
          _createElementVNode("div", _hoisted_50, [
            _createElementVNode("div", _hoisted_51, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (item, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `menu_${i}`,
                  class: _normalizeClass(`menu ${_ctx.tabNextStepCls(item.step)}`),
                  style: _normalizeStyle(`display: ${item.visible === false ? 'none' : ''}`),
                  onClick: ($event: any) => (_ctx.gotoStep(item.step))
                }, [
                  _createElementVNode("div", _hoisted_53, [
                    _createElementVNode("div", _hoisted_54, _toDisplayString(item.name), 1),
                    _createElementVNode("div", _hoisted_55, _toDisplayString(item.title), 1),
                    (item.desc)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_56, _toDisplayString(item.desc), 1))
                      : _createCommentVNode("", true)
                  ])
                ], 14, _hoisted_52))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_57, [
              (_ctx.step == 'values_and_traits')
                ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                    _createVNode(_component_values_and_traits, {
                      onFinishValueAndTraits: _ctx.reTurnValueAndTraits,
                      onFinishAssessment: _ctx.finishAssessment,
                      onSetCanPreviousStep: _ctx.setCanPreviousStep,
                      onSetCanNextStep: _ctx.setCanNextStep,
                      onSetHasPreviousStep: _ctx.setHasPreviousStep,
                      onSetHasNextStep: _ctx.setHasNextStep,
                      onSetTimerLabel: _ctx.setTimerLabel,
                      onSetTimerPercentage: _ctx.setTimerPercentage,
                      lastSection: _ctx.lastSection,
                      valuesAndTraits: _ctx.valuesAndTraits,
                      currentStep: _ctx.currentStep,
                      doNextStep: _ctx.doNextStep,
                      doPreviousStep: _ctx.doPreviousStep
                    }, null, 8, ["onFinishValueAndTraits", "onFinishAssessment", "onSetCanPreviousStep", "onSetCanNextStep", "onSetHasPreviousStep", "onSetHasNextStep", "onSetTimerLabel", "onSetTimerPercentage", "lastSection", "valuesAndTraits", "currentStep", "doNextStep", "doPreviousStep"])
                  ]))
                : (_ctx.step == 'OAIC_archetypes')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                      _createVNode(_component_oaic_archetypes, {
                        onFinishValueAndTraits: _ctx.reTurnOAICArchetypes,
                        onFinishAssessment: _ctx.finishAssessment,
                        onSetCanPreviousStep: _ctx.setCanPreviousStep,
                        onSetCanNextStep: _ctx.setCanNextStep,
                        onSetHasPreviousStep: _ctx.setHasPreviousStep,
                        onSetHasNextStep: _ctx.setHasNextStep,
                        onSetTimerLabel: _ctx.setTimerLabel,
                        onSetTimerPercentage: _ctx.setTimerPercentage,
                        lastSection: _ctx.lastSection,
                        valuesAndTraits: _ctx.valuesAndTraits,
                        currentStep: _ctx.currentStep,
                        doNextStep: _ctx.doNextStep,
                        doPreviousStep: _ctx.doPreviousStep,
                        count: _ctx.count
                      }, null, 8, ["onFinishValueAndTraits", "onFinishAssessment", "onSetCanPreviousStep", "onSetCanNextStep", "onSetHasPreviousStep", "onSetHasNextStep", "onSetTimerLabel", "onSetTimerPercentage", "lastSection", "valuesAndTraits", "currentStep", "doNextStep", "doPreviousStep", "count"])
                    ]))
                  : (_ctx.step == 'acceptable_behaviors')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
                        _createVNode(_component_acceptable_behaviors, {
                          onFinishValueAndTraits: _ctx.reTurnAcceptableBehaviors,
                          onFinishAssessment: _ctx.finishAssessment,
                          onSetCanPreviousStep: _ctx.setCanPreviousStep,
                          onSetCanNextStep: _ctx.setCanNextStep,
                          onSetHasPreviousStep: _ctx.setHasPreviousStep,
                          onSetHasNextStep: _ctx.setHasNextStep,
                          onSetTimerLabel: _ctx.setTimerLabel,
                          onSetTimerPercentage: _ctx.setTimerPercentage,
                          lastSection: _ctx.lastSection,
                          valuesAndTraits: _ctx.valuesAndTraits,
                          currentStep: _ctx.currentStep,
                          doNextStep: _ctx.doNextStep,
                          doPreviousStep: _ctx.doPreviousStep
                        }, null, 8, ["onFinishValueAndTraits", "onFinishAssessment", "onSetCanPreviousStep", "onSetCanNextStep", "onSetHasPreviousStep", "onSetHasNextStep", "onSetTimerLabel", "onSetTimerPercentage", "lastSection", "valuesAndTraits", "currentStep", "doNextStep", "doPreviousStep"])
                      ]))
                    : (_ctx.step == 'work_style_identifier')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                          _createVNode(_component_work_style_identifier, {
                            onFinishValueAndTraits: _ctx.reTurnWorkStyleIdentifier,
                            onFinishAssessment: _ctx.finishAssessment,
                            onSetCanPreviousStep: _ctx.setCanPreviousStep,
                            onSetCanNextStep: _ctx.setCanNextStep,
                            onSetHasPreviousStep: _ctx.setHasPreviousStep,
                            onSetHasNextStep: _ctx.setHasNextStep,
                            onSetTimerLabel: _ctx.setTimerLabel,
                            onSetTimerPercentage: _ctx.setTimerPercentage,
                            lastSection: _ctx.lastSection,
                            valuesAndTraits: _ctx.valuesAndTraits,
                            currentStep: _ctx.currentStep,
                            doNextStep: _ctx.doNextStep,
                            doPreviousStep: _ctx.doPreviousStep
                          }, null, 8, ["onFinishValueAndTraits", "onFinishAssessment", "onSetCanPreviousStep", "onSetCanNextStep", "onSetHasPreviousStep", "onSetHasNextStep", "onSetTimerLabel", "onSetTimerPercentage", "lastSection", "valuesAndTraits", "currentStep", "doNextStep", "doPreviousStep"])
                        ]))
                      : (_ctx.step == 'logic_test')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                            _createVNode(_component_logic_test, {
                              onFinishValueAndTraits: _ctx.returnLogicTest,
                              onFinishAssessment: _ctx.finishAssessment,
                              onSetCanPreviousStep: _ctx.setCanPreviousStep,
                              onSetCanNextStep: _ctx.setCanNextStep,
                              onSetHasPreviousStep: _ctx.setHasPreviousStep,
                              onSetHasNextStep: _ctx.setHasNextStep,
                              onSetTimerLabel: _ctx.setTimerLabel,
                              onSetTimerPercentage: _ctx.setTimerPercentage,
                              lastSection: _ctx.lastSection,
                              valuesAndTraits: _ctx.valuesAndTraits,
                              currentStep: _ctx.currentStep,
                              doNextStep: _ctx.doNextStep,
                              doPreviousStep: _ctx.doPreviousStep
                            }, null, 8, ["onFinishValueAndTraits", "onFinishAssessment", "onSetCanPreviousStep", "onSetCanNextStep", "onSetHasPreviousStep", "onSetHasNextStep", "onSetTimerLabel", "onSetTimerPercentage", "lastSection", "valuesAndTraits", "currentStep", "doNextStep", "doPreviousStep"])
                          ]))
                        : (_ctx.step == 'human_skills')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                              _createVNode(_component_human_skills, {
                                onFinishValueAndTraits: _ctx.reTurnHumanSkills,
                                onFinishAssessment: _ctx.finishAssessment,
                                onSetCanPreviousStep: _ctx.setCanPreviousStep,
                                onSetCanNextStep: _ctx.setCanNextStep,
                                onSetHasPreviousStep: _ctx.setHasPreviousStep,
                                onSetHasNextStep: _ctx.setHasNextStep,
                                onSetTimerLabel: _ctx.setTimerLabel,
                                onSetTimerPercentage: _ctx.setTimerPercentage,
                                lastSection: _ctx.lastSection,
                                valuesAndTraits: _ctx.valuesAndTraits,
                                currentStep: _ctx.currentStep,
                                doNextStep: _ctx.doNextStep,
                                doPreviousStep: _ctx.doPreviousStep
                              }, null, 8, ["onFinishValueAndTraits", "onFinishAssessment", "onSetCanPreviousStep", "onSetCanNextStep", "onSetHasPreviousStep", "onSetHasNextStep", "onSetTimerLabel", "onSetTimerPercentage", "lastSection", "valuesAndTraits", "currentStep", "doNextStep", "doPreviousStep"])
                            ]))
                          : (_ctx.step == 'custom_survey')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                                _createVNode(_component_custom_surveys, {
                                  onFinishValueAndTraits: _ctx.reTurnCustomSurvey,
                                  onFinishAssessment: _ctx.finishAssessment,
                                  onSetCanPreviousStep: _ctx.setCanPreviousStep,
                                  onSetCanNextStep: _ctx.setCanNextStep,
                                  onSetHasPreviousStep: _ctx.setHasPreviousStep,
                                  onSetHasNextStep: _ctx.setHasNextStep,
                                  onSetTimerLabel: _ctx.setTimerLabel,
                                  onSetTimerPercentage: _ctx.setTimerPercentage,
                                  lastSection: _ctx.lastSection,
                                  valuesAndTraits: _ctx.valuesAndTraits,
                                  currentStep: _ctx.currentStep,
                                  doNextStep: _ctx.doNextStep,
                                  doPreviousStep: _ctx.doPreviousStep
                                }, null, 8, ["onFinishValueAndTraits", "onFinishAssessment", "onSetCanPreviousStep", "onSetCanNextStep", "onSetHasPreviousStep", "onSetHasNextStep", "onSetTimerLabel", "onSetTimerPercentage", "lastSection", "valuesAndTraits", "currentStep", "doNextStep", "doPreviousStep"])
                              ]))
                            : _createCommentVNode("", true)
            ]),
            _cache[13] || (_cache[13] = _createElementVNode("div", null, null, -1))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.step == 'complete')
      ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
          _createElementVNode("div", _hoisted_66, [
            _createElementVNode("div", _hoisted_67, [
              (_ctx.companyLogoUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.companyLogoUrl,
                    alt: "Happily logo",
                    class: "company-logo-image"
                  }, null, 8, _hoisted_68))
                : (_openBlock(), _createElementBlock("img", _hoisted_69))
            ]),
            _createElementVNode("div", _hoisted_70, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_71, _toDisplayString(_ctx.$t("question.common.thank_you")), 1),
                _createElementVNode("div", _hoisted_72, _toDisplayString(_ctx.$t("question.common.thank_you_msg")), 1)
              ]),
              _createElementVNode("div", {
                class: "button-wrapper btn-primary",
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.gotoMainPage && _ctx.gotoMainPage(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("question.common.go_to_main_page")), 1),
                _cache[14] || (_cache[14] = _createElementVNode("svg", {
                  width: "16",
                  height: "16",
                  viewBox: "0 0 16 16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    d: "M10.7804 7.33327L7.20442 3.75726L8.14722 2.81445L13.3327 7.99993L8.14722 13.1853L7.20442 12.2425L10.7804 8.6666H2.66602V7.33327H10.7804Z",
                    fill: "white"
                  })
                ], -1))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
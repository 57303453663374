import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/image/logo_myculture.svg'
import _imports_1 from '@/assets/icons/navigate_right_black_24px.png'
import _imports_2 from '@/assets/icons/white_arrow_right_24px.png'


const _hoisted_1 = { class: "menu-navbar-for-test" }
const _hoisted_2 = { class: "intro" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "timer-container" }
const _hoisted_6 = { class: "timer" }
const _hoisted_7 = { class: "time-label-wrapper" }
const _hoisted_8 = { class: "time" }
const _hoisted_9 = { class: "time-label" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  src: _imports_0,
  alt: "Happily logo",
  class: "company-logo-image",
  style: {"width":"18rem"}
}
const _hoisted_13 = {
  key: 2,
  class: "progrees-container mx-auto flex-center center"
}
const _hoisted_14 = { class: "container" }
const _hoisted_15 = { class: "fs-13 fw-700 mb-8" }
const _hoisted_16 = { class: "fs-12 mt-8" }
const _hoisted_17 = { key: 3 }
const _hoisted_18 = { class: "flex-center ml-auto" }
const _hoisted_19 = {
  key: 0,
  class: "ml-auto flex-center"
}
const _hoisted_20 = { class: "max-m-none mr-16" }
const _hoisted_21 = {
  key: 0,
  src: _imports_1,
  class: "icon-24",
  alt: "navigate_right_black_24px"
}
const _hoisted_22 = {
  key: 1,
  src: _imports_2,
  class: "icon-24",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_23 = { class: "assessment-card-container" }
const _hoisted_24 = {
  key: 0,
  class: "assessment-content mb-32"
}
const _hoisted_25 = { class: "width-max-520" }
const _hoisted_26 = { class: "card-title" }
const _hoisted_27 = { class: "fs-16 mt-12" }
const _hoisted_28 = { class: "text-underline" }
const _hoisted_29 = { class: "fs-16 mt-16" }
const _hoisted_30 = { class: "flex-mid" }
const _hoisted_31 = {
  key: 1,
  class: "assessment-content"
}
const _hoisted_32 = { class: "" }
const _hoisted_33 = { class: "question-title" }
const _hoisted_34 = { class: "input-container-02" }
const _hoisted_35 = ["placeholder"]
const _hoisted_36 = { class: "label" }
const _hoisted_37 = { class: "question-title" }
const _hoisted_38 = { class: "input-container-02" }
const _hoisted_39 = ["placeholder"]
const _hoisted_40 = { class: "label" }
const _hoisted_41 = {
  key: 2,
  class: "assessment-content mb-32"
}
const _hoisted_42 = { class: "width-max-520" }
const _hoisted_43 = { class: "fs-24 fw-900" }
const _hoisted_44 = { class: "fs-16 mt-12" }
const _hoisted_45 = { class: "flex-center mt-24" }
const _hoisted_46 = { class: "max-m-none mr-16" }
const _hoisted_47 = { class: "max-m-none mr-16" }
const _hoisted_48 = {
  key: 3,
  class: "assessment-content box-card mb-32"
}
const _hoisted_49 = { class: "width-max-520" }
const _hoisted_50 = { key: 0 }
const _hoisted_51 = { class: "card-title" }
const _hoisted_52 = { class: "fs-16 mt-12" }
const _hoisted_53 = { class: "fw-700" }
const _hoisted_54 = { class: "fs-16 mt-12" }
const _hoisted_55 = { class: "flex-mid" }
const _hoisted_56 = { key: 1 }
const _hoisted_57 = { class: "card-title" }
const _hoisted_58 = { class: "fs-16 mt-12" }
const _hoisted_59 = { class: "fw-700" }
const _hoisted_60 = { class: "fs-16 mt-16" }
const _hoisted_61 = { class: "flex-mid" }
const _hoisted_62 = {
  key: 1,
  class: "mock-menu-container-buttom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["menu-container flex-center fixed", {
      'bottom-mobile': _ctx.step == 'Human_Skills_question',
    }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("main.Human_Skills")), 1),
          _createElementVNode("div", {
            class: "desc",
            innerHTML: _ctx.nl2br(_ctx.$t('question.humanSkills.intro'))
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_progress, {
              type: "circle",
              class: "absolute-mid",
              "show-text": false,
              "stroke-linecap": "square",
              color: _ctx.companyColor,
              width: 128,
              height: 128,
              "stroke-width": 6,
              percentage: ((_ctx.percentageLabel - _ctx.timer) / _ctx.percentageLabel) * 100
            }, null, 8, ["color", "percentage"]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.timerLabel), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("min_remaining")), 1)
            ])
          ])
        ]),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (_ctx.companyLogoUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.companyLogoUrl,
                    alt: "Happily logo",
                    class: "company-logo-image",
                    style: {"width":"18rem"}
                  }, null, 8, _hoisted_11))
                : (_openBlock(), _createElementBlock("img", _hoisted_12)),
              (_ctx.progressShowing)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("main.Human_Skills")), 1),
                      _createVNode(_component_el_progress, {
                        percentage: ((_ctx.percentageLabel - _ctx.timer) / _ctx.percentageLabel) * 100,
                        "show-text": false,
                        color: _ctx.companyColor
                      }, null, 8, ["percentage", "color"]),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("assessmentTest.minutes_remaining_defore")) + " " + _toDisplayString(_ctx.timerLabel) + " " + _toDisplayString(_ctx.$t("assessmentTest.minutes_remaining_after")), 1)
                    ])
                  ]))
                : (_ctx.step == 'opened_question')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, " "))
                  : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_18, [
                (_ctx.step == 'Human_Skills_question')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createElementVNode("div", {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setupNexStep())),
                        class: _normalizeClass(["btn btn-50 btn-primary ml-16", {
                'disable-true': !_ctx.isValid,
              }])
                      }, [
                        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.hasOpenEndedQuestion ? _ctx.$t("btn.Finish") : _ctx.$t("btn.Next")), 1),
                        (_ctx.canNextStep && _ctx.isLightColor)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_21))
                          : (_openBlock(), _createElementBlock("img", _hoisted_22))
                      ], 2)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    false
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["mock-menu-container", {
      'max-m-none': _ctx.step == 'Human_Skills_question',
    }])
        }, null, 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_23, [
      (false && _ctx.step == 'introduce_Human_Skills')
        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t("main.Human_Skills")), 1),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("question.humanSkills.Showcase_your_communication_ability")), 1),
                _cache[10] || (_cache[10] = _createTextVNode("  ")),
                _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t("question.humanSkills.The_best_of_your")), 1),
                _cache[11] || (_cache[11] = _createTextVNode("  ")),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("question.humanSkills.Be_analytical_by_sharing")), 1)
              ]),
              _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t("question.humanSkills.You_will_have_ten_min")), 1),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getStarted())),
                  class: "mx-auto btn btn-50 btn-primary",
                  style: {"margin-top":"8rem"}
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                  _cache[12] || (_cache[12] = _createElementVNode("img", {
                    src: _imports_2,
                    alt: "icons_white_arrow_right_24px",
                    class: "icon-24 ml-16 icon-invert-color"
                  }, null, -1))
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.step == 'introduce_Human_Skills' || _ctx.step == 'Human_Skills_question')
        ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", null, [
                _cache[13] || (_cache[13] = _createElementVNode("span", { class: "question-label" }, "1/2", -1)),
                _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t("assessmentTest.Describe_a_time_when_you")), 1),
                _createElementVNode("div", _hoisted_34, [
                  _withDirectives(_createElementVNode("textarea", {
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.questionOne) = $event)),
                    class: "textrarea-opened-question mt-16 mb-52 input",
                    placeholder: _ctx.$t('question.humanSkills.Write_your_answer_here'),
                    name: "",
                    cols: "30",
                    rows: "10"
                  }, null, 8, _hoisted_35), [
                    [_vModelText, _ctx.questionOne]
                  ]),
                  _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.$t("question.humanSkills.Your_answer")), 1)
                ])
              ]),
              _createElementVNode("div", null, [
                _cache[14] || (_cache[14] = _createElementVNode("span", { class: "question-label" }, "2/2", -1)),
                _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.$t("assessmentTest.Imagine_you_are_working_on")), 1),
                _createElementVNode("div", _hoisted_38, [
                  _withDirectives(_createElementVNode("textarea", {
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.questionTwo) = $event)),
                    class: "textrarea-opened-question mt-16 input",
                    placeholder: _ctx.$t('question.humanSkills.Write_your_answer_here'),
                    name: "",
                    cols: "30",
                    rows: "10"
                  }, null, 8, _hoisted_39), [
                    [_vModelText, _ctx.questionTwo]
                  ]),
                  _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t("question.humanSkills.Your_answer")), 1)
                ])
              ]),
              false
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setupNexStep())),
                    class: _normalizeClass(["btn btn-50 btn-primary mt-24", {
            'disable-true': !_ctx.isValid,
          }])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Submit")), 1)
                  ], 2))
                : _createCommentVNode("", true)
            ])
          ]))
        : (_ctx.step == 'opened_question')
          ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.$t("assessmentTest.Anyz_feedback_or_thoughts")) + _toDisplayString(_ctx.$t("assessmentTest.human_skills_q")), 1),
                _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.$t("assessmentTest.Optional")), 1),
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.openedQuestion) = $event)),
                  class: "textrarea-opened-question mt-16",
                  cols: "30",
                  rows: "10"
                }, null, 512), [
                  [_vModelText, _ctx.openedQuestion]
                ]),
                _createElementVNode("div", _hoisted_45, [
                  _createElementVNode("div", {
                    onClick: _cache[6] || (_cache[6] = ($event: any) => ((_ctx.skipOpenEndedQuestion = true), _ctx.finishOpenedQuestion())),
                    class: "ml-auto btn btn-50 btn-c-8D8D8D",
                    style: {"width":"14.4rem"}
                  }, [
                    _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.$t("btn.Skip")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["ml-auto btn btn-50 btn-primary ml-12", {
              'disable-true': !_ctx.openedQuestion,
            }]),
                    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.finishOpenedQuestion && _ctx.finishOpenedQuestion(...args))),
                    style: {"width":"100%"}
                  }, [
                    _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.$t("btn.Submit")), 1)
                  ], 2)
                ])
              ])
            ]))
          : (_ctx.step == 'complete')
            ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                _createElementVNode("div", _hoisted_49, [
                  (_ctx.lastSection)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                        _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.$t("assessmentTest.You_ve_completed_this_section")), 1),
                        _createElementVNode("div", _hoisted_52, [
                          _createTextVNode(_toDisplayString(_ctx.$t("assessmentTest.Thank_you_for_taking_to_complete")) + " ", 1),
                          _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.$t("main.Human_Skills")), 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.$t("assessmentTest.You_have_completed_all_sections")), 1),
                        _createElementVNode("div", _hoisted_55, [
                          _createElementVNode("div", {
                            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.finishAssessment && _ctx.finishAssessment(...args))),
                            class: "mx-auto btn btn-50 btn-primary",
                            style: {"margin-top":"8rem"}
                          }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close")), 1)
                          ])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_56, [
                        _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.$t("assessmentTest.You_ve_completed_this_section")), 1),
                        _createElementVNode("div", _hoisted_58, [
                          _createTextVNode(_toDisplayString(_ctx.$t("assessmentTest.Thank_you_for_taking_to_complete")) + " ", 1),
                          _createElementVNode("span", _hoisted_59, _toDisplayString(_ctx.$t("main.Human_Skills")), 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.$t("assessmentTest.Once_you_re_ready")), 1),
                        _createElementVNode("div", _hoisted_61, [
                          _createElementVNode("div", {
                            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.nextSectionBTN())),
                            class: "mx-auto btn btn-50 btn-primary",
                            style: {"margin-top":"8rem"}
                          }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("assessmentTest.Go_to_the_Next_Section")), 1),
                            _cache[15] || (_cache[15] = _createElementVNode("img", {
                              src: _imports_2,
                              alt: "icons_white_arrow_right_24px",
                              class: "icon-24 ml-16 icon-invert-color"
                            }, null, -1))
                          ])
                        ])
                      ]))
                ])
              ]))
            : _createCommentVNode("", true)
    ]),
    (false && _ctx.step == 'Human_Skills_question')
      ? (_openBlock(), _createElementBlock("div", _hoisted_62))
      : _createCommentVNode("", true)
  ], 64))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/image/logo_myculture.svg'
import _imports_1 from '@/assets/icons/white_arrow_right_24px.png'
import _imports_2 from '@/assets/icons/navigate_right_black_24px.png'


const _hoisted_1 = { class: "menu-navbar-for-test" }
const _hoisted_2 = { class: "intro" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "timer-container" }
const _hoisted_6 = { class: "timer" }
const _hoisted_7 = { class: "time-label-wrapper" }
const _hoisted_8 = { class: "time" }
const _hoisted_9 = { class: "time-label" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  src: _imports_0,
  alt: "Happily logo",
  class: "company-logo-image",
  style: {"width":"18rem"}
}
const _hoisted_13 = {
  key: 2,
  class: "progrees-container mx-auto flex-center center"
}
const _hoisted_14 = { class: "container" }
const _hoisted_15 = { class: "fs-13 fw-700 mb-8" }
const _hoisted_16 = { class: "fs-12 mt-8" }
const _hoisted_17 = { key: 3 }
const _hoisted_18 = { class: "flex-center ml-auto" }
const _hoisted_19 = { class: "max-m-none mr-16" }
const _hoisted_20 = {
  key: 1,
  class: "ml-auto flex-center"
}
const _hoisted_21 = { class: "icon-18 flex-mid" }
const _hoisted_22 = {
  width: "12",
  height: "12",
  viewBox: "0 0 12 12",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_23 = ["fill"]
const _hoisted_24 = { class: "max-m-none ml-16" }
const _hoisted_25 = { class: "max-m-none mr-16" }
const _hoisted_26 = {
  key: 0,
  src: _imports_2,
  class: "icon-24",
  alt: "navigate_right_black_24px"
}
const _hoisted_27 = {
  key: 1,
  src: _imports_1,
  class: "icon-24",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_28 = {
  key: 2,
  class: "flex-center ml-auto"
}
const _hoisted_29 = { class: "max-m-none mr-16" }
const _hoisted_30 = {
  class: "assessment-card-container",
  style: {"padding":"0"}
}
const _hoisted_31 = {
  key: 0,
  class: "assessment-content mb-32"
}
const _hoisted_32 = { class: "width-max-520" }
const _hoisted_33 = { class: "card-title" }
const _hoisted_34 = { class: "mt-12 card-description" }
const _hoisted_35 = { class: "mt-16 card-description" }
const _hoisted_36 = { class: "text-underline" }
const _hoisted_37 = { class: "min-m-flex-mid min-m-mt-80 max-m-mt-40 width-max-870" }
const _hoisted_38 = {
  key: 1,
  class: "assessment-content mb-32",
  style: {"min-height":"420px"}
}
const _hoisted_39 = { class: "max-width-container" }
const _hoisted_40 = { class: "card-title" }
const _hoisted_41 = { class: "mt-12 card-description" }
const _hoisted_42 = { class: "" }
const _hoisted_43 = { class: "mt-18" }
const _hoisted_44 = {
  class: "tutorial",
  style: {"margin-top":"4rem"}
}
const _hoisted_45 = { class: "select-ab-btn active color-15CE95 flex-center" }
const _hoisted_46 = { class: "text-description flex-center" }
const _hoisted_47 = { class: "select-ab-btn active color-FAA13A flex-center" }
const _hoisted_48 = { class: "text-description flex-center" }
const _hoisted_49 = { class: "select-ab-btn active color-FA8B3A flex-center" }
const _hoisted_50 = { class: "text-description flex-center" }
const _hoisted_51 = { class: "select-ab-btn active color-FA5F3A flex-center" }
const _hoisted_52 = { class: "text-description flex-center" }
const _hoisted_53 = { class: "select-ab-btn active color-FA3A3A flex-center" }
const _hoisted_54 = { class: "text-description flex-center" }
const _hoisted_55 = {
  key: 2,
  class: "assessment-content ab-container",
  style: {"min-height":"420px","max-width":"744px"}
}
const _hoisted_56 = {
  key: 0,
  class: "inner"
}
const _hoisted_57 = { class: "scenario-title-wrapper" }
const _hoisted_58 = { class: "scenario-title" }
const _hoisted_59 = ["innerHTML"]
const _hoisted_60 = { class: "input-wrapper" }
const _hoisted_61 = { class: "radio-group" }
const _hoisted_62 = { class: "x-axis-line" }
const _hoisted_63 = ["onClick"]
const _hoisted_64 = { class: "icon" }
const _hoisted_65 = {
  key: 0,
  width: "14",
  height: "15",
  viewBox: "0 0 14 15",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_66 = ["stroke"]
const _hoisted_67 = {
  key: 1,
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_68 = { key: 0 }
const _hoisted_69 = { class: "card-title" }
const _hoisted_70 = ["innerHTML"]
const _hoisted_71 = ["innerHTML"]
const _hoisted_72 = { class: "scenario-value" }
const _hoisted_73 = { class: "scenario-label" }
const _hoisted_74 = ["onClick"]
const _hoisted_75 = { class: "value-box flex-mid" }
const _hoisted_76 = {
  key: 3,
  class: "assessment-content mb-32"
}
const _hoisted_77 = { class: "width-max-520" }
const _hoisted_78 = { class: "fs-24 fw-900" }
const _hoisted_79 = { class: "mt-12 card-description" }
const _hoisted_80 = { class: "flex-center mt-24" }
const _hoisted_81 = { class: "max-m-none mr-16" }
const _hoisted_82 = { class: "max-m-none mr-16" }
const _hoisted_83 = {
  key: 4,
  class: "assessment-content mb-32"
}
const _hoisted_84 = { class: "width-max-520" }
const _hoisted_85 = { key: 0 }
const _hoisted_86 = { class: "card-title" }
const _hoisted_87 = { class: "mt-12 card-description" }
const _hoisted_88 = { class: "fw-700" }
const _hoisted_89 = { class: "mt-12 card-description" }
const _hoisted_90 = { class: "flex-mid" }
const _hoisted_91 = { key: 1 }
const _hoisted_92 = { class: "card-title" }
const _hoisted_93 = { class: "mt-12 card-description" }
const _hoisted_94 = { class: "fw-700" }
const _hoisted_95 = { class: "fs-16 mt-16" }
const _hoisted_96 = { class: "flex-mid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["menu-container flex-center fixed", {
      'bottom-mobile': _ctx.step == 'Acceptable_Behaviors',
    }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Acceptable_Behaviors")), 1),
          _createElementVNode("div", {
            class: "desc",
            innerHTML: _ctx.nl2br(_ctx.$t('question.acceptableBehaviors.intro'))
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_progress, {
              type: "circle",
              class: "absolute-mid",
              "show-text": false,
              "stroke-linecap": "square",
              color: _ctx.companyColor,
              width: 128,
              height: 128,
              "stroke-width": 6,
              percentage: ((_ctx.percentageLabel - _ctx.timer) / _ctx.percentageLabel) * 100
            }, null, 8, ["color", "percentage"]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.timerLabel), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("min_remaining")), 1)
            ])
          ])
        ]),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (_ctx.companyLogoUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.companyLogoUrl,
                    alt: "Happily logo",
                    class: "company-logo-image",
                    style: {"width":"18rem"}
                  }, null, 8, _hoisted_11))
                : (_openBlock(), _createElementBlock("img", _hoisted_12)),
              (_ctx.step == 'Acceptable_Behaviors')
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("main.Acceptable_Behaviors")), 1),
                      _createVNode(_component_el_progress, {
                        percentage: ((_ctx.percentageLabel - _ctx.timer) / _ctx.percentageLabel) * 100,
                        "show-text": false,
                        color: _ctx.companyColor
                      }, null, 8, ["percentage", "color"]),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("assessmentTest.minutes_remaining_defore")) + " " + _toDisplayString(_ctx.timerLabel) + " " + _toDisplayString(_ctx.$t("assessmentTest.minutes_remaining_after")), 1)
                    ])
                  ]))
                : (
            _ctx.step == 'opened_question' ||
            _ctx.step == 'Evaluating_Acceptable_Behaviors_Tutorial'
          )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, "   "))
                  : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_18, [
                (_ctx.step == 'Evaluating_Acceptable_Behaviors_Tutorial')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (
              (_ctx.step = 'Acceptable_Behaviors'),
                _ctx.clearIntervalSession(),
                _ctx.countDownTime()
            )),
                      class: "ml-auto btn02 btn-40 btn-primary"
                    }, [
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("btn.Next")), 1),
                      _cache[10] || (_cache[10] = _createElementVNode("img", {
                        src: _imports_1,
                        alt: "icons_white_arrow_right_24px",
                        class: "icon-24 icon-invert-color"
                      }, null, -1))
                    ]))
                  : (_ctx.step == 'Acceptable_Behaviors')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                        _createElementVNode("div", {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setupPreviousStep())),
                          class: "btn02-outline btn-40 mr-16"
                        }, [
                          _createElementVNode("div", _hoisted_21, [
                            (_openBlock(), _createElementBlock("svg", _hoisted_22, [
                              _createElementVNode("path", {
                                d: "M3.21861 5.33327L6.79461 1.75726L5.85181 0.814453L0.666341 5.99993L5.85181 11.1853L6.79461 10.2425L3.21861 6.6666H11.333V5.33327H3.21861Z",
                                fill: _ctx.companyColor
                              }, null, 8, _hoisted_23)
                            ]))
                          ]),
                          _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t("btn.Previous")), 1)
                        ]),
                        _createElementVNode("div", {
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setupNexStep())),
                          class: _normalizeClass(["btn02 btn-40 btn-primary ml-16", { 'btn02-disable': !_ctx.canNextStep }])
                        }, [
                          _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t("btn.Next")), 1),
                          (_ctx.canNextStep && _ctx.isLightColor)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_26))
                            : (_openBlock(), _createElementBlock("img", _hoisted_27))
                        ], 2)
                      ]))
                    : (_ctx.step == 'opened_question')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                          _createElementVNode("div", {
                            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.finishOpenedQuestion && _ctx.finishOpenedQuestion(...args))),
                            class: "ml-auto btn02 btn-40 btn-primary"
                          }, [
                            _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t("btn.Finish")), 1),
                            _cache[11] || (_cache[11] = _createElementVNode("img", {
                              src: _imports_1,
                              alt: "icons_white_arrow_right_24px",
                              class: "icon-24 icon-invert-color"
                            }, null, -1))
                          ])
                        ]))
                      : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    false
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["mock-menu-container", {
      'max-m-none': _ctx.step == 'Acceptable_Behaviors',
    }])
        }, null, 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_30, [
      (false && _ctx.step == 'introduce_acceptable_behaviors')
        ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Evaluating_Acceptable_Behaviors")), 1),
              _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.$t(
              "question.acceptableBehaviors.In_the_workplace_it_is_not_uncommon"
            )), 1),
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.$t("question.cultureProfile.Takes")) + " " + _toDisplayString(5 + _ctx.extratime) + " " + _toDisplayString(_ctx.$t("question.cultureProfile.minutes")), 1),
                _createTextVNode("  " + _toDisplayString(_ctx.$t("question.acceptableBehaviors.Please_provide_a_rating_for_each")), 1)
              ]),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.step = 'Evaluating_Acceptable_Behaviors_Tutorial')),
                  class: "min-m-mx-auto btn02 btn-40 btn-primary"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                  _cache[12] || (_cache[12] = _createElementVNode("img", {
                    src: _imports_1,
                    alt: "icons_white_arrow_right_24px",
                    class: "icon-24 ml-16 icon-invert-color"
                  }, null, -1))
                ])
              ])
            ])
          ]))
        : (false && _ctx.step == 'Evaluating_Acceptable_Behaviors_Tutorial')
          ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Evaluating_Acceptable_Behaviors")), 1),
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.$t(
                "question.acceptableBehaviors.In_the_workplace_it_is_not_uncommon"
              )), 1),
                  _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.$t(
                "question.acceptableBehaviors.Please_provide_a_rating_for_each"
              )), 1)
                ]),
                _createElementVNode("div", _hoisted_44, [
                  _createElementVNode("div", _hoisted_45, [
                    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "value-box flex-mid" }, "0", -1)),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Acceptable")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.$t("question.acceptableBehaviors.This_behavior_is_acceptable")), 1),
                  _createElementVNode("div", _hoisted_47, [
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "value-box flex-mid" }, "1", -1)),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Frowned_Upon")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.$t("question.acceptableBehaviors.This_behavior_is_not_acceptable")), 1),
                  _createElementVNode("div", _hoisted_49, [
                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "value-box flex-mid" }, "2", -1)),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Actively_Discouraged")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.$t(
                "question.acceptableBehaviors.This_behavior_is_unacceptable_Discouraged"
              )), 1),
                  _createElementVNode("div", _hoisted_51, [
                    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "value-box flex-mid" }, "3", -1)),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Reprimanded")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_52, _toDisplayString(_ctx.$t(
                "question.acceptableBehaviors.This_behavior_is_unacceptable_Reprimanded"
              )), 1),
                  _createElementVNode("div", _hoisted_53, [
                    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "value-box flex-mid" }, "4", -1)),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Zero_Tolerance_Policy")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.$t(
                "question.acceptableBehaviors.This_behavior_is_irreprehensible"
              )), 1)
                ])
              ])
            ]))
          : (
        _ctx.step == 'introduce_acceptable_behaviors' ||
        (_ctx.step == 'Acceptable_Behaviors' && _ctx.acceptableBehaviors)
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                (_ctx.acceptableBehaviors && _ctx.acceptableBehaviors.scenarios)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.acceptableBehaviors.scenarios, (scenario) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: scenario.id,
                          class: "scenario"
                        }, [
                          _createElementVNode("div", _hoisted_57, [
                            _createElementVNode("div", _hoisted_58, _toDisplayString(_ctx.$t("question.workStyleIdentifier.SCENARIO")) + " " + _toDisplayString(scenario.order), 1),
                            _createElementVNode("div", {
                              class: "scenario-desc",
                              innerHTML: _ctx.lang(scenario.questions[0], 'title')
                            }, null, 8, _hoisted_59)
                          ]),
                          _createElementVNode("div", _hoisted_60, [
                            _createElementVNode("div", _hoisted_61, [
                              _createElementVNode("div", _hoisted_62, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scenario.questions, (question) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: question.id,
                                    class: "label-container"
                                  }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: `${question.id}-${option.value}`,
                                        class: _normalizeClass(["choice-label", [{ active: option.checked }]]),
                                        onClick: ($event: any) => (_ctx.onClickOption(scenario, question, option))
                                      }, [
                                        _createTextVNode(_toDisplayString(_ctx.lang(option, "label")) + " ", 1),
                                        _createElementVNode("div", _hoisted_64, [
                                          (option.checked)
                                            ? (_openBlock(), _createElementBlock("svg", _hoisted_65, [
                                                _createElementVNode("rect", {
                                                  x: "2.25",
                                                  y: "2.75",
                                                  width: "9.5",
                                                  height: "9.5",
                                                  rx: "4.75",
                                                  stroke: _ctx.companyColor,
                                                  "stroke-width": "4.5"
                                                }, null, 8, _hoisted_66)
                                              ]))
                                            : (_openBlock(), _createElementBlock("svg", _hoisted_67, _cache[18] || (_cache[18] = [
                                                _createElementVNode("rect", {
                                                  x: "0.7",
                                                  y: "0.7",
                                                  width: "12.6",
                                                  height: "12.6",
                                                  rx: "6.3",
                                                  stroke: "#E6E8EC",
                                                  "stroke-width": "1.4"
                                                }, null, -1)
                                              ])))
                                        ])
                                      ], 10, _hoisted_63))
                                    }), 128))
                                  ]))
                                }), 128))
                              ])
                            ])
                          ]),
                          (false && _ctx.scenarioIndexShowing == scenario.order)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                                _createElementVNode("div", _hoisted_69, _toDisplayString(_ctx.lang(scenario, "title")), 1),
                                (_ctx.$i18n.getLocale(0) == 'en')
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      class: "mt-12 card-description",
                                      innerHTML: scenario.description
                                    }, null, 8, _hoisted_70))
                                  : _createCommentVNode("", true),
                                (_ctx.$i18n.getLocale(0) == 'th')
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 1,
                                      class: "mt-12 card-description",
                                      innerHTML: scenario.description_th
                                    }, null, 8, _hoisted_71))
                                  : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scenario.questions, (question) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: question.id,
                                    class: "scenario-container",
                                    style: {"margin-top":"4rem"}
                                  }, [
                                    _createElementVNode("div", null, [
                                      _createElementVNode("div", _hoisted_72, _toDisplayString(_ctx.$t("question.workStyleIdentifier.SCENARIO")) + " " + _toDisplayString(scenario.order) + "/" + _toDisplayString(Object.keys(_ctx.acceptableBehaviors.scenarios).length), 1),
                                      _createElementVNode("div", _hoisted_73, _toDisplayString(_ctx.lang(question, "title")), 1)
                                    ]),
                                    _createElementVNode("div", null, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: `${question.id}-${option.value}`,
                                          class: _normalizeClass(["select-ab-btn flex-center", [{ active: option.checked }, _ctx.optionCls(option)]]),
                                          onClick: ($event: any) => (_ctx.onClickOption(scenario, question, option))
                                        }, [
                                          _createElementVNode("div", _hoisted_75, _toDisplayString(option.value), 1),
                                          _createElementVNode("div", null, _toDisplayString(_ctx.lang(option, "label")), 1)
                                        ], 10, _hoisted_74))
                                      }), 128))
                                    ])
                                  ]))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : (_ctx.step == 'opened_question')
              ? (_openBlock(), _createElementBlock("div", _hoisted_76, [
                  _createElementVNode("div", _hoisted_77, [
                    _createElementVNode("div", _hoisted_78, _toDisplayString(_ctx.$t("assessmentTest.Anyz_feedback_or_thoughts")) + _toDisplayString(_ctx.$t("assessmentTest.acceptable_behaviors_q")), 1),
                    _createElementVNode("div", _hoisted_79, _toDisplayString(_ctx.$t("assessmentTest.Optional")), 1),
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.openedQuestion) = $event)),
                      class: "textrarea-opened-question mt-16",
                      cols: "30",
                      rows: "10"
                    }, null, 512), [
                      [_vModelText, _ctx.openedQuestion]
                    ]),
                    _createElementVNode("div", _hoisted_80, [
                      _createElementVNode("div", {
                        onClick: _cache[6] || (_cache[6] = ($event: any) => ((_ctx.skipOpenEndedQuestion = true), _ctx.finishOpenedQuestion())),
                        class: "ml-auto btn btn-50 btn-c-8D8D8D",
                        style: {"width":"14.4rem"}
                      }, [
                        _createElementVNode("span", _hoisted_81, _toDisplayString(_ctx.$t("btn.Skip")), 1)
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["ml-auto btn02 btn-40 btn-primary ml-12", {
              'btn02-disable': !_ctx.openedQuestion,
            }]),
                        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.finishOpenedQuestion && _ctx.finishOpenedQuestion(...args))),
                        style: {"width":"100%"}
                      }, [
                        _createElementVNode("span", _hoisted_82, _toDisplayString(_ctx.$t("btn.Submit")), 1)
                      ], 2)
                    ])
                  ])
                ]))
              : (_ctx.step == 'complete')
                ? (_openBlock(), _createElementBlock("div", _hoisted_83, [
                    _createElementVNode("div", _hoisted_84, [
                      (_ctx.lastSection)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_85, [
                            _createElementVNode("div", _hoisted_86, _toDisplayString(_ctx.$t("assessmentTest.You_ve_completed_this_section")), 1),
                            _createElementVNode("div", _hoisted_87, [
                              _createTextVNode(_toDisplayString(_ctx.$t("assessmentTest.Thank_you_for_taking_to_complete")) + " ", 1),
                              _createElementVNode("span", _hoisted_88, _toDisplayString(_ctx.$t("main.Acceptable_Behaviors")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_89, _toDisplayString(_ctx.$t("assessmentTest.You_have_completed_all_sections")), 1),
                            _createElementVNode("div", _hoisted_90, [
                              _createElementVNode("div", {
                                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.finishAssessment && _ctx.finishAssessment(...args))),
                                class: "mx-auto btn02 btn-40 btn-primary",
                                style: {"margin-top":"8rem"}
                              }, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close")), 1)
                              ])
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_91, [
                            _createElementVNode("div", _hoisted_92, _toDisplayString(_ctx.$t("assessmentTest.You_ve_completed_this_section")), 1),
                            _createElementVNode("div", _hoisted_93, [
                              _createTextVNode(_toDisplayString(_ctx.$t("assessmentTest.Thank_you_for_taking_to_complete")) + " ", 1),
                              _createElementVNode("span", _hoisted_94, _toDisplayString(_ctx.$t("main.Acceptable_Behaviors")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_95, _toDisplayString(_ctx.$t("assessmentTest.Once_you_re_ready")), 1),
                            _createElementVNode("div", _hoisted_96, [
                              _createElementVNode("div", {
                                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.nextSectionBTN())),
                                class: "mx-auto btn02 btn-40 btn-primary",
                                style: {"margin-top":"8rem"}
                              }, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t("assessmentTest.Go_to_the_Next_Section")), 1),
                                _cache[19] || (_cache[19] = _createElementVNode("img", {
                                  src: _imports_1,
                                  alt: "icons_white_arrow_right_24px",
                                  class: "icon-24 ml-16 icon-invert-color"
                                }, null, -1))
                              ])
                            ])
                          ]))
                    ])
                  ]))
                : _createCommentVNode("", true)
    ])
  ], 64))
}
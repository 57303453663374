import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/image/logo_myculture.svg'
import _imports_1 from '@/assets/icons/navigate_right_black_24px.png'
import _imports_2 from '@/assets/icons/white_arrow_right_24px.png'


const _hoisted_1 = { class: "menu-navbar-for-test" }
const _hoisted_2 = { class: "intro" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "timer-container" }
const _hoisted_6 = { class: "timer" }
const _hoisted_7 = { class: "time-label-wrapper" }
const _hoisted_8 = { class: "time" }
const _hoisted_9 = { class: "time-label" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  src: _imports_0,
  alt: "Happily logo",
  class: "company-logo-image",
  style: {"width":"18rem"}
}
const _hoisted_13 = {
  key: 0,
  class: "progrees-container mx-auto flex-center center"
}
const _hoisted_14 = { class: "container" }
const _hoisted_15 = { class: "fs-13 fw-700 mb-8" }
const _hoisted_16 = { class: "fs-12 mt-8" }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "flex-center ml-auto" }
const _hoisted_19 = { class: "max-m-none mr-16" }
const _hoisted_20 = {
  key: 0,
  src: _imports_1,
  class: "icon-24",
  alt: "navigate_right_black_24px"
}
const _hoisted_21 = {
  key: 1,
  src: _imports_2,
  class: "icon-24",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_22 = {
  key: 1,
  class: "flex-center ml-auto"
}
const _hoisted_23 = { class: "icon-18 flex-mid" }
const _hoisted_24 = {
  width: "12",
  height: "12",
  viewBox: "0 0 12 12",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_25 = ["fill"]
const _hoisted_26 = { class: "max-m-none ml-16" }
const _hoisted_27 = {
  key: 0,
  class: "max-m-none mr-16"
}
const _hoisted_28 = {
  key: 1,
  class: "max-m-none mr-16"
}
const _hoisted_29 = {
  key: 2,
  src: _imports_1,
  class: "icon-24",
  alt: "navigate_right_black_24px"
}
const _hoisted_30 = {
  key: 3,
  src: _imports_2,
  class: "icon-24",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_31 = {
  key: 2,
  class: "flex-center ml-auto"
}
const _hoisted_32 = { class: "max-m-none mr-16" }
const _hoisted_33 = { class: "assessment-card-container" }
const _hoisted_34 = {
  key: 0,
  class: "assessment-content mb-32"
}
const _hoisted_35 = { class: "width-max-520" }
const _hoisted_36 = { class: "card-title" }
const _hoisted_37 = { class: "mt-12 card-description" }
const _hoisted_38 = { class: "mt-16 card-description" }
const _hoisted_39 = { class: "text-underline" }
const _hoisted_40 = { class: "min-m-flex-mid min-m-mt-80 max-m-mt-40 width-max-520" }
const _hoisted_41 = { class: "pr-16" }
const _hoisted_42 = {
  key: 1,
  class: "assessment-content mb-32"
}
const _hoisted_43 = {
  key: 1,
  class: "input-group-container"
}
const _hoisted_44 = { class: "card-title" }
const _hoisted_45 = {
  class: "fs-13 mt-12",
  style: {"line-height":"160%"}
}
const _hoisted_46 = { class: "fs-14 opa-08" }
const _hoisted_47 = ["innerHTML"]
const _hoisted_48 = ["innerHTML"]
const _hoisted_49 = { class: "culture-point-label" }
const _hoisted_50 = { class: "input-inner" }
const _hoisted_51 = { class: "input-title" }
const _hoisted_52 = { class: "input-desc text-container" }
const _hoisted_53 = { class: "text" }
const _hoisted_54 = { class: "input-container-wrapper" }
const _hoisted_55 = { class: "input-container-02" }
const _hoisted_56 = ["onUpdate:modelValue", "onInput"]
const _hoisted_57 = { class: "label" }
const _hoisted_58 = ["onClick"]
const _hoisted_59 = ["onClick"]
const _hoisted_60 = {
  key: 2,
  class: "assessment-content mb-32"
}
const _hoisted_61 = {
  key: 1,
  class: "input-group-container"
}
const _hoisted_62 = { class: "card-title" }
const _hoisted_63 = {
  class: "fs-13 mt-12",
  style: {"line-height":"160%"}
}
const _hoisted_64 = { class: "fs-14 opa-08" }
const _hoisted_65 = ["innerHTML"]
const _hoisted_66 = ["innerHTML"]
const _hoisted_67 = { class: "culture-point-label" }
const _hoisted_68 = { class: "input-inner" }
const _hoisted_69 = { class: "input-title" }
const _hoisted_70 = { class: "input-desc text-container" }
const _hoisted_71 = { class: "text" }
const _hoisted_72 = { class: "input-container-wrapper" }
const _hoisted_73 = { class: "input-container-02" }
const _hoisted_74 = ["onUpdate:modelValue", "onInput"]
const _hoisted_75 = { class: "label" }
const _hoisted_76 = ["onClick"]
const _hoisted_77 = ["onClick"]
const _hoisted_78 = {
  key: 3,
  class: "assessment-content mb-32"
}
const _hoisted_79 = {
  key: 1,
  class: "input-group-container"
}
const _hoisted_80 = { class: "card-title" }
const _hoisted_81 = {
  class: "fs-13 mt-12",
  style: {"line-height":"160%"}
}
const _hoisted_82 = { class: "fs-14 opa-08" }
const _hoisted_83 = ["innerHTML"]
const _hoisted_84 = ["innerHTML"]
const _hoisted_85 = { class: "culture-point-label" }
const _hoisted_86 = { class: "input-inner" }
const _hoisted_87 = { class: "input-title" }
const _hoisted_88 = { class: "input-desc text-container" }
const _hoisted_89 = { class: "text" }
const _hoisted_90 = { class: "input-container-wrapper" }
const _hoisted_91 = { class: "input-container-02" }
const _hoisted_92 = ["onUpdate:modelValue", "onInput"]
const _hoisted_93 = { class: "label" }
const _hoisted_94 = ["onClick"]
const _hoisted_95 = ["onClick"]
const _hoisted_96 = {
  key: 4,
  class: "assessment-content mb-32"
}
const _hoisted_97 = {
  key: 1,
  class: "input-group-container"
}
const _hoisted_98 = { class: "card-title" }
const _hoisted_99 = {
  class: "fs-13 mt-12",
  style: {"line-height":"160%"}
}
const _hoisted_100 = { class: "fs-14 opa-08" }
const _hoisted_101 = ["innerHTML"]
const _hoisted_102 = ["innerHTML"]
const _hoisted_103 = { class: "culture-point-label" }
const _hoisted_104 = { class: "input-inner" }
const _hoisted_105 = { class: "input-title" }
const _hoisted_106 = { class: "input-desc text-container" }
const _hoisted_107 = { class: "text" }
const _hoisted_108 = { class: "input-container-wrapper" }
const _hoisted_109 = { class: "input-container-02" }
const _hoisted_110 = ["onUpdate:modelValue", "onInput"]
const _hoisted_111 = { class: "label" }
const _hoisted_112 = ["onClick"]
const _hoisted_113 = ["onClick"]
const _hoisted_114 = {
  key: 5,
  class: "assessment-content mb-32"
}
const _hoisted_115 = {
  key: 1,
  class: "input-group-container"
}
const _hoisted_116 = { class: "card-title" }
const _hoisted_117 = {
  class: "fs-13 mt-12",
  style: {"line-height":"160%"}
}
const _hoisted_118 = { class: "fs-14 opa-08" }
const _hoisted_119 = ["innerHTML"]
const _hoisted_120 = ["innerHTML"]
const _hoisted_121 = { class: "culture-point-label" }
const _hoisted_122 = { class: "input-inner" }
const _hoisted_123 = { class: "input-title" }
const _hoisted_124 = { class: "input-desc text-container" }
const _hoisted_125 = { class: "text" }
const _hoisted_126 = { class: "input-container-wrapper" }
const _hoisted_127 = { class: "input-container-02" }
const _hoisted_128 = ["onUpdate:modelValue", "onInput"]
const _hoisted_129 = { class: "label" }
const _hoisted_130 = ["onClick"]
const _hoisted_131 = ["onClick"]
const _hoisted_132 = {
  key: 6,
  class: "assessment-content mb-32"
}
const _hoisted_133 = {
  key: 1,
  class: "input-group-container"
}
const _hoisted_134 = { class: "card-title" }
const _hoisted_135 = {
  class: "fs-13 mt-12",
  style: {"line-height":"160%"}
}
const _hoisted_136 = { class: "fs-14 opa-08" }
const _hoisted_137 = ["innerHTML"]
const _hoisted_138 = ["innerHTML"]
const _hoisted_139 = { class: "culture-point-label" }
const _hoisted_140 = { class: "input-inner" }
const _hoisted_141 = { class: "input-title" }
const _hoisted_142 = { class: "input-desc text-container" }
const _hoisted_143 = { class: "text" }
const _hoisted_144 = { class: "input-container-wrapper" }
const _hoisted_145 = { class: "input-container-02" }
const _hoisted_146 = ["onUpdate:modelValue", "onInput"]
const _hoisted_147 = { class: "label" }
const _hoisted_148 = ["onClick"]
const _hoisted_149 = ["onClick"]
const _hoisted_150 = {
  key: 7,
  class: "assessment-content mb-32"
}
const _hoisted_151 = { class: "width-max-520" }
const _hoisted_152 = { class: "fs-24 fw-900" }
const _hoisted_153 = {
  class: "fs-13 mt-12",
  style: {"line-height":"160%"}
}
const _hoisted_154 = { class: "flex-center mt-24" }
const _hoisted_155 = { class: "max-m-none mr-16" }
const _hoisted_156 = { class: "" }
const _hoisted_157 = {
  key: 8,
  class: "assessment-content mb-32"
}
const _hoisted_158 = { class: "width-max-520" }
const _hoisted_159 = { key: 0 }
const _hoisted_160 = { class: "card-title" }
const _hoisted_161 = {
  class: "fs-14 mt-12 fc-696C80",
  style: {"line-height":"160%"}
}
const _hoisted_162 = { class: "fw-700" }
const _hoisted_163 = {
  class: "fs-14 mt-12 fc-696C80",
  style: {"line-height":"160%"}
}
const _hoisted_164 = { class: "flex-mid" }
const _hoisted_165 = { key: 1 }
const _hoisted_166 = { class: "card-title" }
const _hoisted_167 = {
  class: "fs-13 mt-12",
  style: {"line-height":"160%"}
}
const _hoisted_168 = { class: "fw-700" }
const _hoisted_169 = { class: "fs-16 mt-16" }
const _hoisted_170 = { class: "flex-mid" }
const _hoisted_171 = {
  key: 1,
  class: "mock-menu-container-buttom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_Doughnut = _resolveComponent("Doughnut")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["menu-container flex-center fixed", {
      'bottom-mobile': _ctx.partAnwser,
    }]),
      style: {"line-height":"160%"}
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("question.cultureProfile.Culture_Profile")), 1),
          _createElementVNode("div", {
            class: "desc",
            innerHTML: _ctx.nl2br(_ctx.$t('question.cultureProfile.intro'))
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_progress, {
              type: "circle",
              class: "absolute-mid",
              "show-text": false,
              "stroke-linecap": "square",
              color: _ctx.companyColor,
              width: 128,
              height: 128,
              "stroke-width": 6,
              percentage: ((_ctx.percentageLabel - _ctx.timer) / _ctx.percentageLabel) * 100
            }, null, 8, ["color", "percentage"]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.timerLabel), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("min_remaining")), 1)
            ])
          ])
        ]),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", {
                class: _normalizeClass({
            'max-m-none': _ctx.partAnwser,
          })
              }, [
                (_ctx.companyLogoUrl)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.companyLogoUrl,
                      alt: "Happily logo",
                      class: "company-logo-image",
                      style: {"width":"18rem"}
                    }, null, 8, _hoisted_11))
                  : (_openBlock(), _createElementBlock("img", _hoisted_12))
              ], 2),
              (_ctx.progressShowing)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("main.Culture_Profile")), 1),
                      _createVNode(_component_el_progress, {
                        percentage: ((_ctx.percentageLabel - _ctx.timer) / _ctx.percentageLabel) * 100,
                        "show-text": false,
                        color: _ctx.companyColor
                      }, null, 8, ["percentage", "color"]),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("assessmentTest.minutes_remaining_defore")) + " " + _toDisplayString(_ctx.timerLabel) + " " + _toDisplayString(_ctx.$t("assessmentTest.minutes_remaining_after")), 1)
                    ])
                  ]))
                : (_ctx.step == 'opened_question')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, " "))
                  : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_18, [
                (_ctx.step == 'OAIC_archetypes_1_6')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextBTN_OAIC_dominant())),
                      class: _normalizeClass(["ml-auto btn02 btn-40 btn-primary", { 'disable-true': _ctx.OAIC_dominant_characteristicsMax != 0 }])
                    }, [
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("btn.Next")), 1),
                      (_ctx.OAIC_dominant_characteristicsMax == 0 && _ctx.isLightColor)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_20))
                        : (_openBlock(), _createElementBlock("img", _hoisted_21))
                    ], 2))
                  : (
              _ctx.step == 'OAIC_archetypes_2_6' ||
              _ctx.step == 'OAIC_archetypes_3_6' ||
              _ctx.step == 'OAIC_archetypes_4_6' ||
              _ctx.step == 'OAIC_archetypes_5_6' ||
              _ctx.step == 'OAIC_archetypes_6_6'
            )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                        _createElementVNode("div", {
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.backBTN_OAIC_dominant && _ctx.backBTN_OAIC_dominant(...args))),
                          class: "btn02-outline btn-40 mr-16"
                        }, [
                          _createElementVNode("div", _hoisted_23, [
                            (_openBlock(), _createElementBlock("svg", _hoisted_24, [
                              _createElementVNode("path", {
                                d: "M3.21861 5.33327L6.79461 1.75726L5.85181 0.814453L0.666341 5.99993L5.85181 11.1853L6.79461 10.2425L3.21861 6.6666H11.333V5.33327H3.21861Z",
                                fill: _ctx.companyColor
                              }, null, 8, _hoisted_25)
                            ]))
                          ]),
                          _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("btn.Previous")), 1)
                        ]),
                        _createElementVNode("div", {
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.nextBTN_OAIC_dominant())),
                          class: _normalizeClass(["ml-auto btn02 btn-40 btn-primary", { 'disable-true': _ctx.nextBTN_OAIC_dominantValidate }])
                        }, [
                          (_ctx.step == 'OAIC_archetypes_6_6')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_ctx.$t("btn.Finish")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_ctx.$t("btn.Next")), 1)),
                          (_ctx.nextBTN_OAIC_dominantValidate == false && _ctx.isLightColor)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_29))
                            : (_openBlock(), _createElementBlock("img", _hoisted_30))
                        ], 2)
                      ]))
                    : (_ctx.step == 'opened_question')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                          _createElementVNode("div", {
                            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.finishOpenedQuestion && _ctx.finishOpenedQuestion(...args))),
                            class: "ml-auto btn02 btn-40 btn-primary"
                          }, [
                            _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t("btn.Finish")), 1),
                            _cache[10] || (_cache[10] = _createElementVNode("img", {
                              src: _imports_2,
                              alt: "icons_white_arrow_right_24px",
                              class: "icon-24 icon-invert-color"
                            }, null, -1))
                          ])
                        ]))
                      : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    false
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["mock-menu-container", {
      'max-m-none': _ctx.partAnwser,
    }])
        }, null, 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_33, [
      (false && _ctx.step == 'introduce_OAIC_archetypes')
        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.$t("question.cultureProfile.Culture_Profile")), 1),
              _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.$t("question.cultureProfile.The_Happily_Culture_Profile")), 1),
              _createElementVNode("div", _hoisted_38, [
                _createTextVNode(_toDisplayString(_ctx.$t("question.cultureProfile.Which_culture_type_should")) + " ", 1),
                _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.$t("question.cultureProfile.Takes")) + " " + _toDisplayString(_ctx.extratime + 5) + " " + _toDisplayString(_ctx.$t("question.cultureProfile.minutes")), 1),
                _createTextVNode("  " + _toDisplayString(_ctx.$t("question.cultureProfile.to_go_evaluate_your_ideal")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("div", {
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.getStarted && _ctx.getStarted(...args))),
                class: "min-m-mx-auto btn02 btn-40 btn-primary"
              }, [
                _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                _cache[11] || (_cache[11] = _createElementVNode("img", {
                  src: _imports_2,
                  alt: "icons_white_arrow_right_24px",
                  class: "icon-18 icon-invert-color"
                }, null, -1))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (
        _ctx.step == 'introduce_OAIC_archetypes' || _ctx.step == 'OAIC_archetypes_1_6'
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
            (_ctx.oaicArchetypes.scenarios)
              ? (_openBlock(), _createBlock(_component_Doughnut, {
                  key: 0,
                  id: "OAIC_archetypes_1_6",
                  q1: _ctx.getQuestionValue('dominantCharacteristics', 0),
                  q2: _ctx.getQuestionValue('dominantCharacteristics', 3),
                  q3: _ctx.getQuestionValue('dominantCharacteristics', 1),
                  q4: _ctx.getQuestionValue('dominantCharacteristics', 2)
                }, null, 8, ["q1", "q2", "q3", "q4"]))
              : _createCommentVNode("", true),
            (_ctx.oaicArchetypes.scenarios)
              ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.dominantCharacteristics, "title")), 1),
                    _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                    _createElementVNode("h3", _hoisted_46, _toDisplayString(_ctx.$t(
                "question.cultureProfile.Which_best_desribes_your_ideal_organization"
              )), 1),
                    (_ctx.$i18n.getLocale(0) == 'en')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "mt-40 culture-profile-title",
                          innerHTML: 
              _ctx.oaicArchetypes.scenarios.dominantCharacteristics.description
            
                        }, null, 8, _hoisted_47))
                      : _createCommentVNode("", true),
                    (_ctx.$i18n.getLocale(0) == 'th')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "mt-40 culture-profile-title",
                          innerHTML: 
              _ctx.oaicArchetypes.scenarios.dominantCharacteristics.description_th
            
                        }, null, 8, _hoisted_48))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.OAIC_dominant_characteristicsMax > -1
                ? _ctx.OAIC_dominant_characteristicsMax
                : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_50, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.dominantCharacteristics
              .questions, (question) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: question.key,
                        class: "input-container fill-number-form"
                      }, [
                        _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.lang(question, "hint")), 1),
                        _createElementVNode("div", _hoisted_52, [
                          _createElementVNode("div", {
                            class: "bg-points",
                            style: _normalizeStyle(`width: ${question.value}%;`)
                          }, null, 4),
                          _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.lang(question, "title")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_54, [
                          _createElementVNode("div", _hoisted_55, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              pattern: "\\d*",
                              class: _normalizeClass(["input input-points", {
                      validate: !_ctx.validateGroupValue('dominantCharacteristics'),
                    }]),
                              "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                              onInput: ($event: any) => (
                      _ctx.onChangeWeight(
                        $event,
                        'dominantCharacteristics',
                        question
                      )
                    )
                            }, null, 42, _hoisted_56), [
                              [_vModelText, question.value]
                            ]),
                            _createElementVNode("label", _hoisted_57, _toDisplayString(_ctx.$t("Rate")), 1)
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(["mc-button mc-button-minus", {
                    disabled: question.value <= 0,
                  }]),
                            onClick: ($event: any) => (_ctx.decreaseValue('dominantCharacteristics', question))
                          }, _cache[12] || (_cache[12] = [
                            _createElementVNode("div", { class: "mc-button-inner" }, "-", -1)
                          ]), 10, _hoisted_58),
                          _createElementVNode("div", {
                            class: _normalizeClass(["mc-button mc-button-plus", {
                    disabled: question.value >= 100,
                  }]),
                            onClick: ($event: any) => (_ctx.increaseValue('dominantCharacteristics', question))
                          }, _cache[13] || (_cache[13] = [
                            _createElementVNode("div", { class: "mc-button-inner" }, "+", -1)
                          ]), 10, _hoisted_59)
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.step == 'OAIC_archetypes_2_6')
        ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
            (_ctx.oaicArchetypes.scenarios)
              ? (_openBlock(), _createBlock(_component_Doughnut, {
                  key: 0,
                  id: "OAIC_archetypes_2_6",
                  q1: _ctx.getQuestionValue('organizationalLeadership', 0),
                  q2: _ctx.getQuestionValue('organizationalLeadership', 3),
                  q3: _ctx.getQuestionValue('organizationalLeadership', 1),
                  q4: _ctx.getQuestionValue('organizationalLeadership', 2)
                }, null, 8, ["q1", "q2", "q3", "q4"]))
              : _createCommentVNode("", true),
            (_ctx.oaicArchetypes.scenarios)
              ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.organizationalLeadership, "title")), 1),
                    _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                    _createElementVNode("h3", _hoisted_64, _toDisplayString(_ctx.$t(
                "question.cultureProfile.Which_best_desribes_your_ideal_leadership"
              )), 1),
                    (_ctx.$i18n.getLocale(0) == 'en')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "mt-40 culture-profile-title",
                          innerHTML: 
              _ctx.oaicArchetypes.scenarios.organizationalLeadership.description
            
                        }, null, 8, _hoisted_65))
                      : _createCommentVNode("", true),
                    (_ctx.$i18n.getLocale(0) == 'th')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "mt-40 culture-profile-title",
                          innerHTML: 
              _ctx.oaicArchetypes.scenarios.organizationalLeadership.description_th
            
                        }, null, 8, _hoisted_66))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_67, _toDisplayString(_ctx.OAIC_dominant_characteristicsMax > -1
                ? _ctx.OAIC_dominant_characteristicsMax
                : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_68, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.organizationalLeadership
              .questions, (question) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: question.key,
                        class: "input-container fill-number-form"
                      }, [
                        _createElementVNode("div", _hoisted_69, _toDisplayString(_ctx.lang(question, "hint")), 1),
                        _createElementVNode("div", _hoisted_70, [
                          _createElementVNode("div", {
                            class: "bg-points",
                            style: _normalizeStyle(`width: ${question.value}%;`)
                          }, null, 4),
                          _createElementVNode("div", _hoisted_71, _toDisplayString(_ctx.lang(question, "title")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_72, [
                          _createElementVNode("div", _hoisted_73, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              pattern: "\\d*",
                              class: _normalizeClass(["input input-points", {
                      validate: !_ctx.validateGroupValue('organizationalLeadership'),
                    }]),
                              "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                              onInput: ($event: any) => (
                      _ctx.onChangeWeight(
                        $event,
                        'organizationalLeadership',
                        question
                      )
                    )
                            }, null, 42, _hoisted_74), [
                              [_vModelText, question.value]
                            ]),
                            _createElementVNode("label", _hoisted_75, _toDisplayString(_ctx.$t("Rate")), 1)
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(["mc-button mc-button-minus", {
                    disabled: question.value <= 0,
                  }]),
                            onClick: ($event: any) => (_ctx.decreaseValue('organizationalLeadership', question))
                          }, _cache[14] || (_cache[14] = [
                            _createElementVNode("div", { class: "mc-button-inner" }, "-", -1)
                          ]), 10, _hoisted_76),
                          _createElementVNode("div", {
                            class: _normalizeClass(["mc-button mc-button-plus", {
                    disabled: question.value >= 100,
                  }]),
                            onClick: ($event: any) => (_ctx.increaseValue('organizationalLeadership', question))
                          }, _cache[15] || (_cache[15] = [
                            _createElementVNode("div", { class: "mc-button-inner" }, "+", -1)
                          ]), 10, _hoisted_77)
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.step == 'OAIC_archetypes_3_6')
        ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
            (_ctx.oaicArchetypes.scenarios)
              ? (_openBlock(), _createBlock(_component_Doughnut, {
                  key: 0,
                  id: "OAIC_archetypes_3_6",
                  q1: _ctx.getQuestionValue('managementOfEmployees', 0),
                  q2: _ctx.getQuestionValue('managementOfEmployees', 3),
                  q3: _ctx.getQuestionValue('managementOfEmployees', 1),
                  q4: _ctx.getQuestionValue('managementOfEmployees', 2)
                }, null, 8, ["q1", "q2", "q3", "q4"]))
              : _createCommentVNode("", true),
            (_ctx.oaicArchetypes.scenarios)
              ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_80, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.managementOfEmployees, "title")), 1),
                    _createElementVNode("div", _hoisted_81, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                    _createElementVNode("h3", _hoisted_82, _toDisplayString(_ctx.$t(
                "question.cultureProfile.Which_best_desribes_the_ideal_role_managers_play"
              )), 1),
                    (_ctx.$i18n.getLocale(0) == 'en')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "mt-40 culture-profile-title",
                          innerHTML: _ctx.oaicArchetypes.scenarios.managementOfEmployees.description
                        }, null, 8, _hoisted_83))
                      : _createCommentVNode("", true),
                    (_ctx.$i18n.getLocale(0) == 'th')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "mt-40 culture-profile-title",
                          innerHTML: 
              _ctx.oaicArchetypes.scenarios.managementOfEmployees.description_th
            
                        }, null, 8, _hoisted_84))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_85, _toDisplayString(_ctx.OAIC_dominant_characteristicsMax > -1
                ? _ctx.OAIC_dominant_characteristicsMax
                : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_86, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.managementOfEmployees
              .questions, (question) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: question.key,
                        class: "input-container fill-number-form"
                      }, [
                        _createElementVNode("div", _hoisted_87, _toDisplayString(_ctx.lang(question, "hint")), 1),
                        _createElementVNode("div", _hoisted_88, [
                          _createElementVNode("div", {
                            class: "bg-points",
                            style: _normalizeStyle(`width: ${question.value}%;`)
                          }, null, 4),
                          _createElementVNode("div", _hoisted_89, _toDisplayString(_ctx.lang(question, "title")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_90, [
                          _createElementVNode("div", _hoisted_91, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              pattern: "\\d*",
                              class: _normalizeClass(["input input-points", {
                      validate: !_ctx.validateGroupValue('managementOfEmployees'),
                    }]),
                              "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                              onInput: ($event: any) => (
                      _ctx.onChangeWeight($event, 'managementOfEmployees', question)
                    )
                            }, null, 42, _hoisted_92), [
                              [_vModelText, question.value]
                            ]),
                            _createElementVNode("label", _hoisted_93, _toDisplayString(_ctx.$t("Rate")), 1)
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(["mc-button mc-button-minus", {
                    disabled: question.value <= 0,
                  }]),
                            onClick: ($event: any) => (_ctx.decreaseValue('managementOfEmployees', question))
                          }, _cache[16] || (_cache[16] = [
                            _createElementVNode("div", { class: "mc-button-inner" }, "-", -1)
                          ]), 10, _hoisted_94),
                          _createElementVNode("div", {
                            class: _normalizeClass(["mc-button mc-button-plus", {
                    disabled: question.value >= 100,
                  }]),
                            onClick: ($event: any) => (_ctx.increaseValue('managementOfEmployees', question))
                          }, _cache[17] || (_cache[17] = [
                            _createElementVNode("div", { class: "mc-button-inner" }, "+", -1)
                          ]), 10, _hoisted_95)
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.step == 'OAIC_archetypes_4_6')
        ? (_openBlock(), _createElementBlock("div", _hoisted_96, [
            (_ctx.oaicArchetypes.scenarios)
              ? (_openBlock(), _createBlock(_component_Doughnut, {
                  key: 0,
                  id: "OAIC_archetypes_4_6",
                  q1: _ctx.getQuestionValue('organizationGlue', 0),
                  q2: _ctx.getQuestionValue('organizationGlue', 3),
                  q3: _ctx.getQuestionValue('organizationGlue', 1),
                  q4: _ctx.getQuestionValue('organizationGlue', 2)
                }, null, 8, ["q1", "q2", "q3", "q4"]))
              : _createCommentVNode("", true),
            (_ctx.oaicArchetypes.scenarios)
              ? (_openBlock(), _createElementBlock("div", _hoisted_97, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_98, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.organizationGlue, "title")), 1),
                    _createElementVNode("div", _hoisted_99, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                    _createElementVNode("h3", _hoisted_100, _toDisplayString(_ctx.$t(
                "question.cultureProfile.Which_best_desribes_what_ideally_holds_the_organization_together"
              )), 1),
                    (_ctx.$i18n.getLocale(0) == 'en')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "mt-40 culture-profile-title",
                          innerHTML: _ctx.oaicArchetypes.scenarios.organizationGlue.description
                        }, null, 8, _hoisted_101))
                      : _createCommentVNode("", true),
                    (_ctx.$i18n.getLocale(0) == 'th')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "mt-40 culture-profile-title",
                          innerHTML: _ctx.oaicArchetypes.scenarios.organizationGlue.description_th
                        }, null, 8, _hoisted_102))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_103, _toDisplayString(_ctx.OAIC_dominant_characteristicsMax > -1
                ? _ctx.OAIC_dominant_characteristicsMax
                : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_104, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.organizationGlue
              .questions, (question) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: question.key,
                        class: "input-container fill-number-form"
                      }, [
                        _createElementVNode("div", _hoisted_105, _toDisplayString(_ctx.lang(question, "hint")), 1),
                        _createElementVNode("div", _hoisted_106, [
                          _createElementVNode("div", {
                            class: "bg-points",
                            style: _normalizeStyle(`width: ${question.value}%;`)
                          }, null, 4),
                          _createElementVNode("div", _hoisted_107, _toDisplayString(_ctx.lang(question, "title")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_108, [
                          _createElementVNode("div", _hoisted_109, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              pattern: "\\d*",
                              class: _normalizeClass(["input input-points", {
                      validate: !_ctx.validateGroupValue('organizationGlue'),
                    }]),
                              "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                              onInput: ($event: any) => (
                      _ctx.onChangeWeight($event, 'organizationGlue', question)
                    )
                            }, null, 42, _hoisted_110), [
                              [_vModelText, question.value]
                            ]),
                            _createElementVNode("label", _hoisted_111, _toDisplayString(_ctx.$t("Rate")), 1)
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(["mc-button mc-button-minus", {
                    disabled: question.value <= 0,
                  }]),
                            onClick: ($event: any) => (_ctx.decreaseValue('organizationGlue', question))
                          }, _cache[18] || (_cache[18] = [
                            _createElementVNode("div", { class: "mc-button-inner" }, "-", -1)
                          ]), 10, _hoisted_112),
                          _createElementVNode("div", {
                            class: _normalizeClass(["mc-button mc-button-plus", {
                    disabled: question.value >= 100,
                  }]),
                            onClick: ($event: any) => (_ctx.increaseValue('organizationGlue', question))
                          }, _cache[19] || (_cache[19] = [
                            _createElementVNode("div", { class: "mc-button-inner" }, "+", -1)
                          ]), 10, _hoisted_113)
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.step == 'OAIC_archetypes_5_6')
        ? (_openBlock(), _createElementBlock("div", _hoisted_114, [
            (_ctx.oaicArchetypes.scenarios)
              ? (_openBlock(), _createBlock(_component_Doughnut, {
                  key: 0,
                  id: "OAIC_archetypes_5_6",
                  q1: _ctx.getQuestionValue('strategicEmphases', 0),
                  q2: _ctx.getQuestionValue('strategicEmphases', 3),
                  q3: _ctx.getQuestionValue('strategicEmphases', 1),
                  q4: _ctx.getQuestionValue('strategicEmphases', 2)
                }, null, 8, ["q1", "q2", "q3", "q4"]))
              : _createCommentVNode("", true),
            (_ctx.oaicArchetypes.scenarios)
              ? (_openBlock(), _createElementBlock("div", _hoisted_115, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_116, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.strategicEmphases, "title")), 1),
                    _createElementVNode("div", _hoisted_117, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                    _createElementVNode("h3", _hoisted_118, _toDisplayString(_ctx.$t(
                "question.cultureProfile.Which_best_desribes_the_basis_of_how_decisions_should_be_made"
              )), 1),
                    (_ctx.$i18n.getLocale(0) == 'en')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "mt-40 culture-profile-title",
                          innerHTML: _ctx.oaicArchetypes.scenarios.strategicEmphases.description
                        }, null, 8, _hoisted_119))
                      : _createCommentVNode("", true),
                    (_ctx.$i18n.getLocale(0) == 'th')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "mt-40 culture-profile-title",
                          innerHTML: _ctx.oaicArchetypes.scenarios.strategicEmphases.description_th
                        }, null, 8, _hoisted_120))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_121, _toDisplayString(_ctx.OAIC_dominant_characteristicsMax > -1
                ? _ctx.OAIC_dominant_characteristicsMax
                : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_122, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.strategicEmphases
              .questions, (question) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: question.key,
                        class: "input-container fill-number-form"
                      }, [
                        _createElementVNode("div", _hoisted_123, _toDisplayString(_ctx.lang(question, "hint")), 1),
                        _createElementVNode("div", _hoisted_124, [
                          _createElementVNode("div", {
                            class: "bg-points",
                            style: _normalizeStyle(`width: ${question.value}%;`)
                          }, null, 4),
                          _createElementVNode("div", _hoisted_125, _toDisplayString(_ctx.lang(question, "title")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_126, [
                          _createElementVNode("div", _hoisted_127, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              pattern: "\\d*",
                              class: _normalizeClass(["input input-points", {
                      validate: !_ctx.validateGroupValue('strategicEmphases'),
                    }]),
                              "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                              onInput: ($event: any) => (
                      _ctx.onChangeWeight($event, 'strategicEmphases', question)
                    )
                            }, null, 42, _hoisted_128), [
                              [_vModelText, question.value]
                            ]),
                            _createElementVNode("label", _hoisted_129, _toDisplayString(_ctx.$t("Rate")), 1)
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(["mc-button mc-button-minus", {
                    disabled: question.value <= 0,
                  }]),
                            onClick: ($event: any) => (_ctx.decreaseValue('strategicEmphases', question))
                          }, _cache[20] || (_cache[20] = [
                            _createElementVNode("div", { class: "mc-button-inner" }, "-", -1)
                          ]), 10, _hoisted_130),
                          _createElementVNode("div", {
                            class: _normalizeClass(["mc-button mc-button-plus", {
                    disabled: question.value >= 100,
                  }]),
                            onClick: ($event: any) => (_ctx.increaseValue('strategicEmphases', question))
                          }, _cache[21] || (_cache[21] = [
                            _createElementVNode("div", { class: "mc-button-inner" }, "+", -1)
                          ]), 10, _hoisted_131)
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.step == 'OAIC_archetypes_6_6')
        ? (_openBlock(), _createElementBlock("div", _hoisted_132, [
            (_ctx.oaicArchetypes.scenarios)
              ? (_openBlock(), _createBlock(_component_Doughnut, {
                  key: 0,
                  id: "OAIC_archetypes_6_6",
                  q1: _ctx.getQuestionValue('criteriaOfSuccess', 0),
                  q2: _ctx.getQuestionValue('criteriaOfSuccess', 3),
                  q3: _ctx.getQuestionValue('criteriaOfSuccess', 1),
                  q4: _ctx.getQuestionValue('criteriaOfSuccess', 2)
                }, null, 8, ["q1", "q2", "q3", "q4"]))
              : _createCommentVNode("", true),
            (_ctx.oaicArchetypes.scenarios)
              ? (_openBlock(), _createElementBlock("div", _hoisted_133, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_134, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.criteriaOfSuccess, "title")), 1),
                    _createElementVNode("div", _hoisted_135, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                    _createElementVNode("h3", _hoisted_136, _toDisplayString(_ctx.$t(
                "question.cultureProfile.Which_best_desribes_what_success_looks_like"
              )), 1),
                    (_ctx.$i18n.getLocale(0) == 'en')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "mt-40 culture-profile-title",
                          innerHTML: _ctx.oaicArchetypes.scenarios.criteriaOfSuccess.description
                        }, null, 8, _hoisted_137))
                      : _createCommentVNode("", true),
                    (_ctx.$i18n.getLocale(0) == 'th')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "mt-40 culture-profile-title",
                          innerHTML: _ctx.oaicArchetypes.scenarios.criteriaOfSuccess.description_th
                        }, null, 8, _hoisted_138))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_139, _toDisplayString(_ctx.OAIC_dominant_characteristicsMax > -1
                ? _ctx.OAIC_dominant_characteristicsMax
                : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_140, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.criteriaOfSuccess
              .questions, (question) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: question.key,
                        class: "input-container fill-number-form"
                      }, [
                        _createElementVNode("div", _hoisted_141, _toDisplayString(_ctx.lang(question, "hint")), 1),
                        _createElementVNode("div", _hoisted_142, [
                          _createElementVNode("div", {
                            class: "bg-points",
                            style: _normalizeStyle(`width: ${question.value}%;`)
                          }, null, 4),
                          _createElementVNode("div", _hoisted_143, _toDisplayString(_ctx.lang(question, "title")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_144, [
                          _createElementVNode("div", _hoisted_145, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              pattern: "\\d*",
                              class: _normalizeClass(["input input-points", {
                      validate: !_ctx.validateGroupValue('criteriaOfSuccess'),
                    }]),
                              "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                              onInput: ($event: any) => (
                      _ctx.onChangeWeight($event, 'criteriaOfSuccess', question)
                    )
                            }, null, 42, _hoisted_146), [
                              [_vModelText, question.value]
                            ]),
                            _createElementVNode("label", _hoisted_147, _toDisplayString(_ctx.$t("Rate")), 1)
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(["mc-button mc-button-minus", {
                    disabled: question.value <= 0,
                  }]),
                            onClick: ($event: any) => (_ctx.decreaseValue('criteriaOfSuccess', question))
                          }, _cache[22] || (_cache[22] = [
                            _createElementVNode("div", { class: "mc-button-inner" }, "-", -1)
                          ]), 10, _hoisted_148),
                          _createElementVNode("div", {
                            class: _normalizeClass(["mc-button mc-button-plus", {
                    disabled: question.value >= 100,
                  }]),
                            onClick: ($event: any) => (_ctx.increaseValue('criteriaOfSuccess', question))
                          }, _cache[23] || (_cache[23] = [
                            _createElementVNode("div", { class: "mc-button-inner" }, "+", -1)
                          ]), 10, _hoisted_149)
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_ctx.step == 'opened_question')
          ? (_openBlock(), _createElementBlock("div", _hoisted_150, [
              _createElementVNode("div", _hoisted_151, [
                _createElementVNode("div", _hoisted_152, _toDisplayString(_ctx.$t("assessmentTest.Anyz_feedback_or_thoughts")) + _toDisplayString(_ctx.$t("assessmentTest.culture_profile_q")), 1),
                _createElementVNode("div", _hoisted_153, _toDisplayString(_ctx.$t("assessmentTest.Optional")), 1),
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.openedQuestion) = $event)),
                  class: "textrarea-opened-question mt-16",
                  cols: "30",
                  rows: "10"
                }, null, 512), [
                  [_vModelText, _ctx.openedQuestion]
                ]),
                _createElementVNode("div", _hoisted_154, [
                  _createElementVNode("div", {
                    onClick: _cache[6] || (_cache[6] = ($event: any) => ((_ctx.skipOpenEndedQuestion = true), _ctx.finishOpenedQuestion())),
                    class: "ml-auto btn02 btn-40 btn-c-8D8D8D",
                    style: {"width":"14.4rem"}
                  }, [
                    _createElementVNode("span", _hoisted_155, _toDisplayString(_ctx.$t("btn.Skip")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["ml-auto btn02 btn-40 btn-primary ml-12 flex-center", {
              'btn02-disable': !_ctx.openedQuestion,
            }]),
                    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.finishOpenedQuestion && _ctx.finishOpenedQuestion(...args))),
                    style: {"width":"100%"}
                  }, [
                    _createElementVNode("span", _hoisted_156, _toDisplayString(_ctx.$t("btn.Submit")), 1)
                  ], 2)
                ])
              ])
            ]))
          : (_ctx.step == 'complete')
            ? (_openBlock(), _createElementBlock("div", _hoisted_157, [
                _createElementVNode("div", _hoisted_158, [
                  (_ctx.lastSection)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_159, [
                        _createElementVNode("div", _hoisted_160, _toDisplayString(_ctx.$t("assessmentTest.You_ve_completed_this_section")), 1),
                        _createElementVNode("div", _hoisted_161, [
                          _createTextVNode(_toDisplayString(_ctx.$t("assessmentTest.Thank_you_for_taking_to_complete")) + " ", 1),
                          _createElementVNode("span", _hoisted_162, _toDisplayString(_ctx.$t("main.Culture_Profile")), 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_163, _toDisplayString(_ctx.$t("assessmentTest.You_have_completed_all_sections")), 1),
                        _createElementVNode("div", _hoisted_164, [
                          _createElementVNode("div", {
                            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.finishAssessment && _ctx.finishAssessment(...args))),
                            class: "mx-auto btn02 btn-40 btn-primary",
                            style: {"margin-top":"8rem"}
                          }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close")), 1)
                          ])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_165, [
                        _createElementVNode("div", _hoisted_166, _toDisplayString(_ctx.$t("assessmentTest.You_ve_completed_this_section")), 1),
                        _createElementVNode("div", _hoisted_167, [
                          _createTextVNode(_toDisplayString(_ctx.$t("assessmentTest.Thank_you_for_taking_to_complete")) + " ", 1),
                          _createElementVNode("span", _hoisted_168, _toDisplayString(_ctx.$t("main.Culture_Profile")), 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_169, _toDisplayString(_ctx.$t("assessmentTest.Once_you_re_ready")), 1),
                        _createElementVNode("div", _hoisted_170, [
                          _createElementVNode("div", {
                            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.nextSectionBTN())),
                            class: "mx-auto btn02 btn-40 btn-primary",
                            style: {"margin-top":"8rem"}
                          }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("assessmentTest.Go_to_the_Next_Section")), 1),
                            _cache[24] || (_cache[24] = _createElementVNode("img", {
                              src: _imports_2,
                              alt: "icons_white_arrow_right_24px",
                              class: "icon-24 ml-16 icon-invert-color"
                            }, null, -1))
                          ])
                        ])
                      ]))
                ])
              ]))
            : _createCommentVNode("", true)
    ]),
    (false && _ctx.partAnwser)
      ? (_openBlock(), _createElementBlock("div", _hoisted_171))
      : _createCommentVNode("", true)
  ], 64))
}
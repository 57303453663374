import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/image/logo_myculture.svg'
import _imports_1 from '@/assets/icons/navigate_right_black_24px.png'
import _imports_2 from '@/assets/icons/white_arrow_right_24px.png'
import _imports_3 from '@/assets/icons/white_check_2_32px.png'


const _hoisted_1 = { class: "menu-navbar-for-test" }
const _hoisted_2 = { class: "intro" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "desc" }
const _hoisted_5 = { class: "timer-container" }
const _hoisted_6 = { class: "timer" }
const _hoisted_7 = { class: "time-label-wrapper" }
const _hoisted_8 = { class: "time" }
const _hoisted_9 = { class: "time-label" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  src: _imports_0,
  alt: "Happily logo",
  class: "company-logo-image",
  style: {"width":"18rem"}
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 0,
  class: "progrees-container mx-auto flex-center center"
}
const _hoisted_14 = { class: "container" }
const _hoisted_15 = { class: "fs-13 fw-700 mb-8" }
const _hoisted_16 = { class: "fs-12 mt-8" }
const _hoisted_17 = {
  key: 1,
  class: "max-m-none"
}
const _hoisted_18 = {
  key: 2,
  class: "flex-center ml-auto"
}
const _hoisted_19 = { class: "max-m-none mr-16" }
const _hoisted_20 = {
  key: 0,
  src: _imports_1,
  class: "icon-24",
  alt: "navigate_right_black_24px"
}
const _hoisted_21 = {
  key: 1,
  src: _imports_2,
  class: "icon-18",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_22 = {
  key: 3,
  class: "flex-center ml-auto"
}
const _hoisted_23 = { class: "icon-18 flex-mid" }
const _hoisted_24 = {
  width: "12",
  height: "12",
  viewBox: "0 0 12 12",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_25 = ["fill"]
const _hoisted_26 = { class: "max-m-none ml-16" }
const _hoisted_27 = { class: "max-m-none mr-16" }
const _hoisted_28 = {
  key: 0,
  src: _imports_1,
  class: "icon-24",
  alt: "navigate_right_black_24px"
}
const _hoisted_29 = {
  key: 1,
  src: _imports_2,
  class: "icon-18",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_30 = {
  key: 4,
  class: "flex-center ml-auto"
}
const _hoisted_31 = { class: "max-m-none mr-16" }
const _hoisted_32 = { class: "assessment-card-container" }
const _hoisted_33 = {
  key: 0,
  class: "assessment-content"
}
const _hoisted_34 = { class: "width-max-520" }
const _hoisted_35 = { class: "card-title" }
const _hoisted_36 = { class: "mt-12 card-description" }
const _hoisted_37 = { class: "mt-16 card-description" }
const _hoisted_38 = { class: "text-underline" }
const _hoisted_39 = { class: "min-m-flex-mid min-m-mt-80 max-m-mt-40 width-max-870" }
const _hoisted_40 = { class: "max-m-mr-auto" }
const _hoisted_41 = {
  key: 1,
  class: "assessment-content mb-32"
}
const _hoisted_42 = { class: "width-max-870" }
const _hoisted_43 = { class: "card-title" }
const _hoisted_44 = {
  key: 0,
  class: "fs-14 mt-12",
  style: {"line-height":"160%"}
}
const _hoisted_45 = {
  key: 1,
  class: "fs-16 mt-34"
}
const _hoisted_46 = {
  key: 2,
  class: "select-value-container mt-32"
}
const _hoisted_47 = ["onClick"]
const _hoisted_48 = { class: "label-wrapper" }
const _hoisted_49 = { class: "label" }
const _hoisted_50 = { class: "description" }
const _hoisted_51 = {
  key: 2,
  class: "assessment-content mb-32"
}
const _hoisted_52 = { class: "width-max-870" }
const _hoisted_53 = { class: "card-title" }
const _hoisted_54 = {
  class: "fs-14 mt-12",
  style: {"line-height":"160%"}
}
const _hoisted_55 = { class: "fs-16 mt-34" }
const _hoisted_56 = {
  key: 0,
  class: "select-value-container mt-24"
}
const _hoisted_57 = ["onClick"]
const _hoisted_58 = { class: "label" }
const _hoisted_59 = {
  key: 3,
  class: "assessment-content mb-32"
}
const _hoisted_60 = { class: "width-max-870" }
const _hoisted_61 = { class: "card-title" }
const _hoisted_62 = {
  class: "fs-14 mt-12",
  style: {"line-height":"160%"}
}
const _hoisted_63 = { class: "flex-center mt-24" }
const _hoisted_64 = { class: "" }
const _hoisted_65 = { class: "mr-16" }
const _hoisted_66 = {
  key: 4,
  class: "assessment-content mb-32"
}
const _hoisted_67 = { class: "width-max-520" }
const _hoisted_68 = { key: 0 }
const _hoisted_69 = { class: "card-title" }
const _hoisted_70 = { class: "card-description mt-12" }
const _hoisted_71 = { class: "fw-700" }
const _hoisted_72 = { class: "card-description mt-16" }
const _hoisted_73 = { class: "flex-mid" }
const _hoisted_74 = { key: 1 }
const _hoisted_75 = { class: "card-title" }
const _hoisted_76 = {
  class: "fs-14 mt-12",
  style: {"line-height":"160%"}
}
const _hoisted_77 = { class: "fw-700" }
const _hoisted_78 = { class: "fs-16 mt-16" }
const _hoisted_79 = { class: "flex-mid" }
const _hoisted_80 = {
  key: 1,
  class: "mock-menu-container-buttom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["menu-container flex-center fixed", {
      'bottom-mobile':
        _ctx.step == 'select_ten_value' || _ctx.step == 'select_ten_traits',
    }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("question.valueAndTraits.Values")), 1),
          _createElementVNode("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.$t("question.valueAndTraits.intro")) + " ", 1),
            _createElementVNode("strong", null, _toDisplayString(_ctx.$t("question.valueAndTraits.intro_highlight")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_progress, {
              type: "circle",
              class: "absolute-mid",
              "show-text": false,
              "stroke-linecap": "square",
              color: _ctx.companyColor,
              width: 128,
              height: 128,
              "stroke-width": 6,
              percentage: ((_ctx.percentageLabel - _ctx.timer) / _ctx.percentageLabel) * 100
            }, null, 8, ["color", "percentage"]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.timerLabel), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("min_remaining")), 1)
            ])
          ])
        ]),
        false
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass({
          'max-m-none':
            _ctx.step == 'select_ten_value' || _ctx.step == 'select_ten_traits',
        })
            }, [
              (_ctx.companyLogoUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.companyLogoUrl,
                    alt: "Happily logo",
                    class: "company-logo-image",
                    style: {"width":"18rem"}
                  }, null, 8, _hoisted_10))
                : (_openBlock(), _createElementBlock("img", _hoisted_11))
            ], 2))
          : _createCommentVNode("", true),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (_ctx.progressShowing)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("main.Values_and_Traits")), 1),
                      _createVNode(_component_el_progress, {
                        percentage: ((_ctx.percentageLabel - _ctx.timer) / _ctx.percentageLabel) * 100,
                        "show-text": false,
                        color: _ctx.companyColor
                      }, null, 8, ["percentage", "color"]),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("assessmentTest.minutes_remaining_defore")) + " " + _toDisplayString(_ctx.timerLabel) + " " + _toDisplayString(_ctx.$t("assessmentTest.minutes_remaining_after")), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.step == 'opened_question')
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, " "))
                : _createCommentVNode("", true),
              (_ctx.step == 'select_ten_value')
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createElementVNode("div", {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.finishValue())),
                      class: _normalizeClass(["ml-auto btn02 btn-40 btn-primary", {
              'disable-true': !_ctx.canFinishValues,
            }])
                    }, [
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("btn.Next")), 1),
                      (
                _ctx.selectedValues.length == _ctx.MINIMUM_VALUES_CHOICES && _ctx.isLightColor
              )
                        ? (_openBlock(), _createElementBlock("img", _hoisted_20))
                        : (_openBlock(), _createElementBlock("img", _hoisted_21))
                    ], 2)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.step == 'select_ten_traits')
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                    _createElementVNode("div", {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.step = 'select_ten_value')),
                      class: "btn02-outline btn-40 mr-16"
                    }, [
                      _createElementVNode("div", _hoisted_23, [
                        (_openBlock(), _createElementBlock("svg", _hoisted_24, [
                          _createElementVNode("path", {
                            d: "M3.21861 5.33327L6.79461 1.75726L5.85181 0.814453L0.666341 5.99993L5.85181 11.1853L6.79461 10.2425L3.21861 6.6666H11.333V5.33327H3.21861Z",
                            fill: _ctx.companyColor
                          }, null, 8, _hoisted_25)
                        ]))
                      ]),
                      _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("btn.Previous")), 1)
                    ]),
                    _createElementVNode("div", {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.finishTraits())),
                      class: _normalizeClass(["ml-auto btn02 btn-40 btn-primary", {
              'disable-true': _ctx.selectedTraits.length != _ctx.MINIMUM_TRAITS_CHOICES,
            }])
                    }, [
                      _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t("btn.Next")), 1),
                      (
                _ctx.selectedTraits.length == _ctx.MINIMUM_TRAITS_CHOICES && _ctx.isLightColor
              )
                        ? (_openBlock(), _createElementBlock("img", _hoisted_28))
                        : (_openBlock(), _createElementBlock("img", _hoisted_29))
                    ], 2)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.step == 'opened_question')
                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                    _createElementVNode("div", {
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.finishOpenedQuestion && _ctx.finishOpenedQuestion(...args))),
                      class: "ml-auto btn02 btn-40 btn-primary"
                    }, [
                      _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t("btn.Finish")), 1),
                      _cache[10] || (_cache[10] = _createElementVNode("img", {
                        src: _imports_2,
                        alt: "icons_white_arrow_right_24px",
                        class: "icon-18 icon-invert-color"
                      }, null, -1))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    false
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["mock-menu-container", {
      'max-m-none': _ctx.step == 'select_ten_value' || _ctx.step == 'select_ten_traits',
    }])
        }, null, 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_32, [
      (false && _ctx.step == 'introduce_values_and_traits')
        ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.$t("question.valueAndTraits.Values_and_Traits")), 1),
              _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.$t("question.valueAndTraits.Your_values_guide_your")), 1),
              _createElementVNode("div", _hoisted_37, [
                _createTextVNode(_toDisplayString(_ctx.$t("question.valueAndTraits.In_this_sectio_you_have")) + " ", 1),
                _createElementVNode("span", _hoisted_38, _toDisplayString(5 + _ctx.extratime) + " " + _toDisplayString(_ctx.$t("question.valueAndTraits.minutes")), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("question.valueAndTraits.to_identify_and_select_your")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("div", {
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.getStarted && _ctx.getStarted(...args))),
                class: "min-m-mx-auto btn02 btn-40 btn-primary"
              }, [
                _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                _cache[11] || (_cache[11] = _createElementVNode("img", {
                  src: _imports_2,
                  alt: "icons_white_arrow_right_24px",
                  class: "icon-18 ml-16 max-m-ml-auto icon-invert-color"
                }, null, -1))
              ])
            ])
          ]))
        : (
        _ctx.step == 'introduce_values_and_traits' || _ctx.step == 'select_ten_value'
      )
          ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.$t("question.valueAndTraits.Select_the")) + " " + _toDisplayString(_ctx.MINIMUM_VALUES_CHOICES) + " " + _toDisplayString(_ctx.$t("question.valueAndTraits.values_that_best_describe")), 1),
                false
                  ? (_openBlock(), _createElementBlock("div", _hoisted_44, _toDisplayString(_ctx.$t("question.valueAndTraits.Click_on_a_word_to_add")), 1))
                  : _createCommentVNode("", true),
                false
                  ? (_openBlock(), _createElementBlock("div", _hoisted_45, _toDisplayString(_ctx.$t("question.valueAndTraits.SELECT")) + " " + _toDisplayString(_ctx.MINIMUM_VALUES_CHOICES - _ctx.selectedValues.length) + "  " + _toDisplayString(_ctx.$t("question.valueAndTraits.MORE_ITEMS")), 1))
                  : _createCommentVNode("", true),
                (
            _ctx.valuesAndTraits &&
            _ctx.valuesAndTraits.scenarios &&
            _ctx.valuesAndTraits.scenarios.definingYourValues &&
            _ctx.valuesAndTraits.scenarios.definingYourValues.questions
          )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesAndTraits.scenarios.definingYourValues
              .questions, (data) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: data.id,
                          class: _normalizeClass(["option-card flex-center", `${_ctx.styleSelectedValue(data.id)}`]),
                          onClick: ($event: any) => (_ctx.selectedTenValue(data))
                        }, [
                          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "check-box flex-mid" }, [
                            _createElementVNode("div", { class: "checked flex-mid" }, [
                              _createElementVNode("img", {
                                src: _imports_3,
                                alt: "icons_white_check_2_32px",
                                class: "icon-12"
                              })
                            ])
                          ], -1)),
                          _createElementVNode("div", _hoisted_48, [
                            _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.lang(data, "title")), 1),
                            _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.lang(data, "desc")), 1)
                          ])
                        ], 10, _hoisted_47))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : (_ctx.step == 'select_ten_traits')
            ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                _createElementVNode("div", _hoisted_52, [
                  _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t("question.valueAndTraits.Select_the")) + " " + _toDisplayString(_ctx.MINIMUM_TRAITS_CHOICES) + " " + _toDisplayString(_ctx.$t("question.valueAndTraits.traits_that_best_describe")), 1),
                  _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.$t("question.valueAndTraits.Click_on_a_word_to_add")), 1),
                  _createElementVNode("div", _hoisted_55, _toDisplayString(_ctx.$t("question.valueAndTraits.SELECT")) + " " + _toDisplayString(_ctx.MINIMUM_TRAITS_CHOICES - _ctx.selectedTraits.length) + "  " + _toDisplayString(_ctx.$t("question.valueAndTraits.MORE_ITEMS")), 1),
                  (
            _ctx.valuesAndTraits &&
            _ctx.valuesAndTraits.scenarios &&
            _ctx.valuesAndTraits.scenarios.identifyingKeyTraits &&
            _ctx.valuesAndTraits.scenarios.identifyingKeyTraits.questions
          )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesAndTraits.scenarios.identifyingKeyTraits
              .questions, (data) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: data.id,
                            class: _normalizeClass(["option-card flex-center", `${_ctx.styleSelectedTraits(data.id)}`]),
                            onClick: ($event: any) => (_ctx.selectedTenTraits(data))
                          }, [
                            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "check-box flex-mid" }, [
                              _createElementVNode("div", { class: "checked flex-mid" }, [
                                _createElementVNode("img", {
                                  src: _imports_3,
                                  alt: "icons_white_check_2_32px",
                                  class: "icon-12"
                                })
                              ])
                            ], -1)),
                            _createElementVNode("div", _hoisted_58, _toDisplayString(_ctx.lang(data, "title")), 1)
                          ], 10, _hoisted_57))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : (_ctx.step == 'opened_question')
              ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                  _createElementVNode("div", _hoisted_60, [
                    _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.$t("assessmentTest.Anyz_feedback_or_thoughts")) + _toDisplayString(_ctx.$t("assessmentTest.values_and_traits_q")), 1),
                    _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.$t("assessmentTest.Optional")), 1),
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.openedQuestion) = $event)),
                      class: "textrarea-opened-question mt-16",
                      cols: "30",
                      rows: "10"
                    }, null, 512), [
                      [_vModelText, _ctx.openedQuestion]
                    ]),
                    _createElementVNode("div", _hoisted_63, [
                      _createElementVNode("div", {
                        onClick: _cache[6] || (_cache[6] = ($event: any) => ((_ctx.skipOpenEndedQuestion = true), _ctx.finishOpenedQuestion())),
                        class: "ml-auto btn02-outline btn-40 flex-mid",
                        style: {"width":"14.4rem"}
                      }, [
                        _createElementVNode("span", _hoisted_64, _toDisplayString(_ctx.$t("btn.Skip")), 1)
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["ml-auto btn02 btn-40 btn-primary ml-12 flex-mid", {
              'btn02-disable': !_ctx.openedQuestion,
            }]),
                        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.finishOpenedQuestion && _ctx.finishOpenedQuestion(...args))),
                        style: {"width":"100%"}
                      }, [
                        _createElementVNode("span", _hoisted_65, _toDisplayString(_ctx.$t("btn.Submit")), 1)
                      ], 2)
                    ])
                  ])
                ]))
              : (_ctx.step == 'complete')
                ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
                    _createElementVNode("div", _hoisted_67, [
                      (_ctx.lastSection)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                            _createElementVNode("div", _hoisted_69, _toDisplayString(_ctx.$t("assessmentTest.You_ve_completed_this_section")), 1),
                            _createElementVNode("div", _hoisted_70, [
                              _createTextVNode(_toDisplayString(_ctx.$t("assessmentTest.Thank_you_for_taking_to_complete")) + " ", 1),
                              _createElementVNode("span", _hoisted_71, _toDisplayString(_ctx.$t("main.Values_and_Traits")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_72, _toDisplayString(_ctx.$t("assessmentTest.You_have_completed_all_sections")), 1),
                            _createElementVNode("div", _hoisted_73, [
                              _createElementVNode("div", {
                                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.finishAssessment && _ctx.finishAssessment(...args))),
                                class: "mx-auto btn02 btn-40 btn-primary",
                                style: {"margin-top":"8rem"}
                              }, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close")), 1)
                              ])
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_74, [
                            _createElementVNode("div", _hoisted_75, _toDisplayString(_ctx.$t("assessmentTest.You_ve_completed_this_section")), 1),
                            _createElementVNode("div", _hoisted_76, [
                              _createTextVNode(_toDisplayString(_ctx.$t("assessmentTest.Thank_you_for_taking_to_complete")) + " ", 1),
                              _createElementVNode("span", _hoisted_77, _toDisplayString(_ctx.$t("main.Values_and_Traits")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_78, _toDisplayString(_ctx.$t("assessmentTest.Once_you_re_ready")), 1),
                            _createElementVNode("div", _hoisted_79, [
                              _createElementVNode("div", {
                                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.nextSectionBTN())),
                                class: "mx-auto btn02 btn-40 btn-primary",
                                style: {"margin-top":"8rem"}
                              }, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t("assessmentTest.Go_to_the_Next_Section")), 1),
                                _cache[14] || (_cache[14] = _createElementVNode("img", {
                                  src: _imports_2,
                                  alt: "icons_white_arrow_right_24px",
                                  class: "icon-18 ml-16 icon-invert-color"
                                }, null, -1))
                              ])
                            ])
                          ]))
                    ])
                  ]))
                : _createCommentVNode("", true)
    ]),
    ((false && _ctx.step == 'select_ten_value') || _ctx.step == 'select_ten_traits')
      ? (_openBlock(), _createElementBlock("div", _hoisted_80))
      : _createCommentVNode("", true)
  ], 64))
}